import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {withTranslation} from 'react-i18next';
import {Button, Form, Row, Col, Card} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const Authorization = localStorage.getItem('token');
const headers = {
    'Content-Type': 'application/json',
    'Authorization': Authorization,
};

class About extends Component {

    constructor (props, context) {
        super(props, context);
        this.state = {
            configs: [],
            about_fr: "",
            about_en: "",
            more_fr: "",
            more_en: "",
            about_fr_id: "",
            about_en_id: "",
            more_fr_id: "",
            more_en_id: "",
        };

        this.handleSubmitAboutfr = this.handleSubmitAboutfr.bind(this);
        this.handleSubmitAbouten = this.handleSubmitAbouten.bind(this);
        this.handleSubmitMorefr = this.handleSubmitMorefr.bind(this);
        this.handleSubmitMoreen = this.handleSubmitMoreen.bind(this);

    }

    componentDidMount () {
        axios.get(`${BASE_URL}/api/config`, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    const configs = res.data.config;
                    configs.forEach((item) => {
                        if (item.key === "about_mobile_fr") {
                            this.setState({
                                about_fr: item.value,
                                about_fr_id: item.id,
                            });
                        } else if (item.key === "about_mobile_en") {
                            this.setState({
                                about_en: item.value,
                                about_en_id: item.id,
                            });
                        } else if (item.key === "learnmore_mobile_fr") {
                            this.setState({
                                more_fr: item.value,
                                more_fr_id: item.id,
                            });
                        }
                        else if (item.key === "learnmore_mobile_en") {
                            this.setState({
                                more_en: item.value,
                                more_en_id: item.id,
                            });
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });

    }

    handleInputChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log("NAME", e.target.value);
    };

    handleSubmitAboutfr = (id) => (e) => {
        e.preventDefault();
        const {about_fr} = this.state;
        axios.put(`${BASE_URL}/api/config/${id}`, {value: about_fr}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleSubmitAbouten = (id) => (e) => {
        e.preventDefault();
        const {about_en} = this.state;
        axios.put(`${BASE_URL}/api/config/${id}`, {value: about_en}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleSubmitMorefr = (id) => (e) => {
        e.preventDefault();
        const {more_fr} = this.state;
        axios.put(`${BASE_URL}/api/config/${id}`, {value: more_fr}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleSubmitMoreen = (id) => (e) => {
        e.preventDefault();
        const {more_en} = this.state;
        axios.put(`${BASE_URL}/api/config/${id}`, {value: more_en}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }

    render () {
        return (
            <section>
                <div className="col-md-12">
                    <h1>{this.props.t('section_about_title')}</h1>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={12}>
                                <Form.Label>Label Fr</Form.Label>
                                {/* <Form.Control as="textarea" rows="6" name="about_fr" value={this.state.about_fr} onChange={this.handleInputChange} required /> */}
                                <Card border="secondary" style={{border: '1px solid gray'}}>
                                    <Card.Body>
                                        <CKEditor
                                            editor={InlineEditor}
                                            data={this.state.about_fr}
                                            config={{
                                                // toolbar: [ 'heading', '|', 'strikethrough', 'underline','code', 'codeBlock' , 'bold', 'italic', 'underline', 'strike' ,'link', 'bulletedList', 'numberedList', '-', 'blockQuote' ]
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        // 'fontfamily', 'fontsize', '|',
                                                        // 'alignment', '|',
                                                        // 'fontColor', 'fontBackgroundColor', '|',
                                                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                        'link', '|',
                                                        // 'outdent', 'indent', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        // 'code', 'codeBlock', '|',
                                                        // 'insertTable', '|',
                                                        // 'imageUpload', 'blockQuote', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    shouldNotGroupWhenFull: true
                                                }
                                            }}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // console.log( { event, editor, data } );
                                                this.state.about_fr = data;
                                            }}
                                            onBlur={(event, editor) => {
                                                // console.log( 'Blur.', editor );
                                            }}
                                            onFocus={(event, editor) => {
                                                // console.log( 'Focus.', editor );
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Button className="mt-2 float-right" variant="outline-dark" onClick={this.handleSubmitAboutfr(this.state.about_fr_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                            <Col xs={12}>
                                <Form.Label>Label En</Form.Label>
                                {/* <Form.Control as="textarea" rows="6" name="about_en" value={this.state.about_en} onChange={this.handleInputChange} required /> */}
                                <Card border="secondary" style={{border: '1px solid gray'}}>
                                    <Card.Body>
                                        <CKEditor
                                            editor={InlineEditor}
                                            data={this.state.about_en}
                                            config={{
                                                // toolbar: [ 'heading', '|', 'strikethrough', 'underline','code', 'codeBlock' , 'bold', 'italic', 'underline', 'strike' ,'link', 'bulletedList', 'numberedList', '-', 'blockQuote' ]
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        // 'fontfamily', 'fontsize', '|',
                                                        // 'alignment', '|',
                                                        // 'fontColor', 'fontBackgroundColor', '|',
                                                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                        'link', '|',
                                                        // 'outdent', 'indent', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        // 'code', 'codeBlock', '|',
                                                        // 'insertTable', '|',
                                                        // 'imageUpload', 'blockQuote', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    shouldNotGroupWhenFull: true
                                                }
                                            }}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // console.log( { event, editor, data } );
                                                this.state.about_en = data;
                                            }}
                                            onBlur={(event, editor) => {
                                                // console.log( 'Blur.', editor );
                                            }}
                                            onFocus={(event, editor) => {
                                                // console.log( 'Focus.', editor );
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Button className="mt-2 float-right" variant="outline-dark" onClick={this.handleSubmitAbouten(this.state.about_en_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>

                <div className="col-md-12 mt-3">
                    <h1>{this.props.t('section_learn_more_title')}</h1>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={12}>
                                <Form.Label>Label Fr</Form.Label>
                                {/* <Form.Control as="textarea" rows="8" name="more_fr" value={this.state.more_fr} onChange={this.handleInputChange} required /> */}
                                <Card border="secondary" style={{border: '1px solid gray'}}>
                                    <Card.Body>
                                        <CKEditor
                                            editor={InlineEditor}
                                            data={this.state.more_fr}
                                            config={{
                                                // toolbar: [ 'heading', '|', 'strikethrough', 'underline','code', 'codeBlock' , 'bold', 'italic', 'underline', 'strike' ,'link', 'bulletedList', 'numberedList', '-', 'blockQuote' ]
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        // 'fontfamily', 'fontsize', '|',
                                                        // 'alignment', '|',
                                                        // 'fontColor', 'fontBackgroundColor', '|',
                                                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                        'link', '|',
                                                        // 'outdent', 'indent', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        // 'code', 'codeBlock', '|',
                                                        // 'insertTable', '|',
                                                        // 'imageUpload', 'blockQuote', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    shouldNotGroupWhenFull: true
                                                }
                                            }}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // console.log( { event, editor, data } );
                                                this.state.more_fr = data;
                                            }}
                                            onBlur={(event, editor) => {
                                                // console.log( 'Blur.', editor );
                                            }}
                                            onFocus={(event, editor) => {
                                                // console.log( 'Focus.', editor );
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Button className="mt-2 float-right" variant="outline-dark" onClick={this.handleSubmitMorefr(this.state.more_fr_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                            <Col xs={12}>
                                <Form.Label>Label En</Form.Label>
                                {/* <Form.Control as="textarea" rows="8" name="more_en" value={this.state.more_en} onChange={this.handleInputChange} required /> */}
                                <Card border="secondary" style={{border: '1px solid gray'}}>
                                    <Card.Body>
                                        <CKEditor
                                            editor={InlineEditor}
                                            data={this.state.more_en}
                                            config={{
                                                // toolbar: [ 'heading', '|', 'strikethrough', 'underline','code', 'codeBlock' , 'bold', 'italic', 'underline', 'strike' ,'link', 'bulletedList', 'numberedList', '-', 'blockQuote' ]
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        // 'fontfamily', 'fontsize', '|',
                                                        // 'alignment', '|',
                                                        // 'fontColor', 'fontBackgroundColor', '|',
                                                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                        'link', '|',
                                                        // 'outdent', 'indent', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        // 'code', 'codeBlock', '|',
                                                        // 'insertTable', '|',
                                                        // 'imageUpload', 'blockQuote', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    shouldNotGroupWhenFull: true
                                                }
                                            }}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // console.log( { event, editor, data } );
                                                this.state.more_en = data;
                                            }}
                                            onBlur={(event, editor) => {
                                                // console.log( 'Blur.', editor );
                                            }}
                                            onFocus={(event, editor) => {
                                                // console.log( 'Focus.', editor );
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Button className="mt-2 float-right" variant="outline-dark" onClick={this.handleSubmitMoreen(this.state.more_en_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>

                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />
            </section>
        );
    }
}

export default withTranslation()(About);