import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Button, Form} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import {withTranslation} from 'react-i18next';
//import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";

class AddBeneficiaire extends Component {

    constructor (props, context) {
        super(props, context);
        this.state = {
            nom: "",
            pos: [],
            pos_id: "",
            email: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const {nom, email} = this.state;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };

        axios.post(`${BASE_URL}/api/beneficiaires`, {nom, email}, {headers})
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({show: true});
                    setTimeout(() => {
                        this.props.history.push("/dashboard/beneficiaires");
                    }, 2000);
                } else {
                    console.log('KO');
                }
            })
            .catch(error => {
                throw (error);
            });
    };



    handleReset = () => {
        this.setState({
            nom: "",
            email: ""
        });
    };

    render () {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('add_beneficiaire')}</h1>
                <div className="row justify-content-left">
                    <div className="col-md-6">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formLabel">
                                <Form.Label>{this.props.t('name')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('name')} name="nom" onChange={this.handleInputChange} required />
                            </Form.Group>
                            {/* <Form.Group controlId="formEmail">
                                <Form.Label>{this.props.t('email')}</Form.Label>
                                <Form.Control type="email" placeholder={this.props.t('email')} name="email" onChange={this.handleInputChange} required />
                            </Form.Group> */}
                            <div className="mt-4 text-right">
                                <Button className="mr-2" variant="outline-secondary" onClick={this.handleReset} type="reset">{this.props.t('btn_cancel')}</Button>
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                            </div>
                        </Form>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        text="Success"
                        confirmButtonText='OK'
                        type="success"
                        onConfirm={() => this.setState({show: false})}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(AddBeneficiaire);