import React, {Component} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../../assets/css/login.css';
import {BASE_URL} from '../../config';
import 'react-phone-number-input/style.css'
import 'react-responsive-ui/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import {withTranslation} from 'react-i18next';
import SweetAlert from 'sweetalert2-react';

class FormRegister extends Component {
    state = {
        nom: "",
        prenom: "",
        organisation: "",
        email: "",
        password: "",
        confirmPassword: '',
        telephone: "",
        isAuth: false
    };
    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    componentDidMount () {
        if ("token" in localStorage) {
            this.props.history.push("/dashboard");
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        if (this.state.nom.trim() &&
            this.state.prenom.trim() &&
            this.state.organisation.trim() &&
            this.state.email.trim() &&
            this.state.password.trim() &&
            this.state.confirmPassword.trim() &&
            isValidPhoneNumber(this.state.telephone)
        ) {
            const {nom, prenom, organisation, email, password, confirmPassword, telephone} = this.state;
            if (password !== confirmPassword) {
                alert("Passwords don't match");
            } else {
                axios.post(`${BASE_URL}/api/auth/inscription`, {nom, prenom, organisation, email, password, telephone})
                    .then(response => {
                        if (response.data.status === "success") {
                            this.setState({show: true});
                            this.handleReset();
                            this.props.history.push("/login");
                        }
                    })
                    .catch(error => {
                        throw (error);
                    });
            }
        }
    };

    handleReset = () => {
        this.setState({
            nom: "",
            prenom: "",
            organisation: "",
            email: "",
            password: "",
            telephone: ""
        });
    };
    render () {
        const {telephone} = this.state
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-10">
                            <h1 className="text-center">{this.props.t('signup')}</h1>
                            <Form className="py-3" onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Label>{this.props.t('nom')}</Form.Label>
                                            <Form.Control type="text" placeholder={this.props.t('nom')} name="nom" onChange={this.handleInputChange} required />
                                        </Col>
                                        <Col>
                                            <Form.Label>{this.props.t('prenom')}</Form.Label>
                                            <Form.Control type="text" placeholder={this.props.t('prenom')} name="prenom" onChange={this.handleInputChange} required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="formOrganisation">
                                    <Form.Label>{this.props.t('organisation')}</Form.Label>
                                    <Form.Control type="text" placeholder={this.props.t('organisation')} name="organisation" onChange={this.handleInputChange} required />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>{this.props.t('email')}</Form.Label>
                                    <Form.Control type="email" placeholder={this.props.t('email')} name="email" onChange={this.handleInputChange} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{this.props.t('password')} </Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control type="password" autoComplete="off" placeholder={this.props.t('password')} name="password" onChange={this.handleInputChange} required />
                                        </Col>
                                        <Col>
                                            <Form.Control type="password" autoComplete="off" placeholder={this.props.t('confirm_pass')} name="confirmPassword" onChange={this.handleInputChange} required />
                                        </Col>
                                    </Row>

                                </Form.Group>
                                <Form.Group controlId="formPhone">
                                    <Form.Label>{this.props.t('phone')}</Form.Label>
                                    <PhoneInput
                                        className="form-control"
                                        placeholder={this.props.t('phone')}
                                        value={this.state.telephone}
                                        onChange={telephone => this.setState({telephone})}
                                        error={telephone ? (isValidPhoneNumber(telephone) ? undefined : 'Invalide phone number') : ''} required />

                                </Form.Group>
                                <div className="mt-5 d-flex justify-content-center align-items-center">

                                    <Button className="mr-4" variant="outline-dark" type="submit">{this.props.t('submit')}</Button>

                                    <span className="mr-4"> | </span>

                                    <Link to="/login" >{this.props.t('btn_login')}</Link>

                                </div>

                            </Form>
                        </div>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        type="success"
                        text={this.props.t('register_ok')}
                        onConfirm={() => this.setState({show: false})}
                    />
                </div>
            </section>
        );
    }
}


export default withTranslation()(FormRegister);
