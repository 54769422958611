import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {withTranslation} from 'react-i18next';
import SweetAlert from 'sweetalert2-react';
import Moment from 'react-moment';
import MomentLib from 'moment';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Event extends Component {

    state = {
        programs: []
    }

    constructor (props, context) {
        super(props, context);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        // get programs
        axios.get(`${BASE_URL}/api/programmes?type=of`, {headers})
            .then(res => {
                const programs = res.data.programmes;

                programs.forEach((program) => {
                    if (program.active === true) {
                        program.active = "Actif";
                    } else {
                        program.active = "Inactif";
                    }
                });
                this.setState({programs: programs});
                console.log('this.state.programs.', this.state.programs);
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleDelete = (id) => {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Authorization': Authorization,
            'Content-Type': 'application/json'
        };

        axios.delete(`${BASE_URL}/api/programmes/${id}`, {headers})
            .then(response => {
                this.setState({show: true});
                axios.get(`${BASE_URL}/api/programmes`)
                    .then(res => {
                        const programs = res.data.programmes;

                        programs.forEach((program) => {
                            if (program.active === true) {
                                program.active = "Actif";
                            } else {
                                program.active = "Inactif";
                            }
                        });
                        setTimeout(() => {
                            this.setState({programs: programs});
                        }, 2000);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                throw (error);
            });
    }


    render () {
        const editItemFormatter = (cell, row, rowIndex) => {
            return <div className="d-flex justify-content-around">
                <Link to={"/dashboard/events/" + cell}>
                    <FeatherIcon icon="eye" size="48" color="#36909b" />
                </Link>
                <Link to="/dashboard/events"
                    onClick={() => {
                        if (window.confirm(`${this.props.t('confirm_del_events')}`)) {
                            this.handleDelete(cell);
                        }
                    }}
                >
                    <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
                </Link>

            </div>
        };

        const categorieFormatter = (Event, row, rowIndex) => {
            if (Event) {
                return Event.nom;
            }
            return '-';
        };
        const dateFormatter = (date, row, rowIndex) => {
            return <Moment format="D MMM YYYY HH:mm" withTitle>
                {date}
            </Moment>
        };

        const sortDates = (a, b, order) => {
            var aDateMoment = MomentLib(a);
            var bDateMoment = MomentLib(b);

            if (order === 'asc') {
                return aDateMoment.unix() - bDateMoment.unix();
            }
            else if (order === 'desc') {
                return bDateMoment.unix() - aDateMoment.unix();
            }
        }

        const cols = [{
            dataField: 'nom',
            text: this.props.t('name'),
            sort: true
        }, {
            dataField: 'Event',
            text: this.props.t('categorie'),
            formatter: categorieFormatter,
            sort: true
        }, {
            dataField: 'debut',
            text: this.props.t('debut'),
            formatter: dateFormatter,
            sort: true,
            dataSort: true,
            sortFunc: sortDates
        }, {
            dataField: 'fin',
            text: this.props.t('fin'),
            formatter: dateFormatter,
            sort: true,
            dataSort: true,
            sortFunc: sortDates
        }, {
            dataField: 'prix',
            text: this.props.t('prix'),
            sort: true
        }, {
            dataField: 'url',
            text: 'URL'
        }, {
            dataField: 'active',
            text: this.props.t('state')
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: editItemFormatter
        },
        ];
        return (
            <div className="col-md-12">
                <h1>{this.props.t('events')}</h1>
                <br />
                <BootstrapTable
                    keyField='id'
                    data={this.state.programs}
                    columns={cols}
                    pagination={paginationFactory()}
                />
                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    onConfirm={() => this.setState({show: false})}
                />
            </div>
        );
    }
}

export default withTranslation()(Event);