import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {withTranslation} from 'react-i18next';
class LeftMenu extends Component {
    constructor (props, context) {
        super(props, context);

        this.state = {
            openOf: false,
            openProduit: false,
            openItem: false,
            openEventMenu: false,
            openEvent: false,
            openPos: false,
            openSales: false,
            openCampaigns: false,
            openProgram: false,
            openBeneficiaire: false,
            openRepartition: false,
            openRepartitions: false,
            openConfig: false,
        };
    }

    render () {
        let isAgent = false;
        const {openOf} = this.state;
        const {openProduit} = this.state;
        const {openItem} = this.state;
        const {openEvent} = this.state;
        const {openSales} = this.state;
        const {openCampaigns} = this.state;
        const {openPos} = this.state;
        const {openEventMenu} = this.state;
        const {openProgram} = this.state;
        const {openBeneficiaire} = this.state;
        const {openRepartition} = this.state;
        const {openRepartitions} = this.state;
        const {openConfig} = this.state;


        if (localStorage.getItem("label") === "BMA_USER") {
            isAgent = true
        }

        return (
            <nav className="bg-light pt-5" id="sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        {isAgent ?
                            <span>
                                <li className="nav-item">
                                    <span className="nav-link"
                                        onClick={() => this.setState({openOf: !openOf})}
                                        aria-controls="o_f"
                                        aria-expanded={openOf}>
                                        <FeatherIcon icon="users" />
                                        <span className="pl-2">{this.props.t('users')}</span>
                                    </span>
                                </li>
                                <Collapse in={this.state.openOf}>
                                    <div className="pl-4" id="o_f">
                                        <li className="nav-item" >
                                            <Link className="nav-link" to="/dashboard/agents">
                                                <FeatherIcon icon="list" />
                                                <span className="pl-2">{this.props.t('super_admins')}</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item" >
                                            <Link className="nav-link" to="/dashboard/of">
                                                <FeatherIcon icon="list" />
                                                <span className="pl-2">{this.props.t('clients')}</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item" >
                                            <Link className="nav-link" to="/dashboard/of/ajout">
                                                <FeatherIcon icon="plus-circle" />
                                                <span className="pl-2">{this.props.t('add_user')}</span>
                                            </Link>
                                        </li>
                                    </div>
                                </Collapse>
                            </span>
                            :
                            <span></span>

                        }
                        {!isAgent &&
                            <span>
                                <li className="nav-item" >
                                    <Link className="nav-link" to="/dashboard">
                                        <FeatherIcon icon="bar-chart-2" />
                                        <span className="pl-2">{this.props.t('statistics')}</span>
                                    </Link>
                                </li>
                            </span>

                        }
                        {!isAgent &&
                            <span>
                                <li className="nav-item">
                                    <span className="nav-link"
                                        onClick={() => this.setState({openEventMenu: !openEventMenu})}
                                        aria-controls="event"
                                        aria-expanded={openEventMenu}>
                                        <FeatherIcon icon="calendar" />
                                        <span className="pl-2">{this.props.t('event')}</span>
                                    </span>
                                </li>
                                <Collapse in={this.state.openEventMenu}>
                                    <div className="pl-4" id="events">
                                        <li className="nav-item">
                                            <span className="nav-link"
                                                onClick={() => this.setState({openEvent: !openEvent})}
                                                aria-controls="events"
                                                aria-expanded={openEvent}>
                                                <FeatherIcon icon="calendar" />
                                                <span className="pl-2">{this.props.t('program')}</span>
                                            </span>
                                        </li>
                                        <Collapse in={this.state.openEvent}>
                                            <div className="pl-4" id="events">
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/events">
                                                        <FeatherIcon icon="list" />
                                                        <span className="pl-2">{this.props.t('liste')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/events/add">
                                                        <FeatherIcon icon="plus-circle" />
                                                        <span className="pl-2">{this.props.t('add_event')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <span className="nav-link"
                                                        onClick={() => this.setState({openProgram: !openProgram})}
                                                        aria-controls="programs"
                                                        aria-expanded={openProgram}>
                                                        <FeatherIcon icon="layers" />
                                                        <span className="pl-2">{this.props.t('categories')}</span>
                                                    </span>
                                                </li>
                                                <Collapse in={this.state.openProgram}>
                                                    <div className="pl-4" id="programs">
                                                        <li className="nav-item" >
                                                            <Link className="nav-link" to="/dashboard/categories">
                                                                <FeatherIcon icon="list" />
                                                                <span className="pl-2">{this.props.t('liste')}</span>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item" >
                                                            <Link className="nav-link" to="/dashboard/categories/add">
                                                                <FeatherIcon icon="plus-circle" />
                                                                <span className="pl-2">{this.props.t('add_categorie')}</span>
                                                            </Link>
                                                        </li>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Collapse>
                                        <li className="nav-item" >
                                            <span className="nav-link"
                                                onClick={() => this.setState({openConfig: !openConfig})}
                                                aria-controls="setting"
                                                aria-expanded={openConfig}>
                                                <FeatherIcon icon="settings" />
                                                <span className="pl-2">{this.props.t('reglages')}</span>
                                            </span>
                                        </li>
                                        <Collapse in={this.state.openConfig}>
                                            <div className="pl-4" id="setting">
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/settings">
                                                        <FeatherIcon icon="image" />
                                                        <span className="pl-2">{this.props.t('event_screen')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/settings/about">
                                                        <FeatherIcon icon="file-text" />
                                                        <span className="pl-2">{this.props.t('event_about')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/settings/config">
                                                        <FeatherIcon icon="file-text" />
                                                        <span className="pl-2">{this.props.t('event_settings')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/settings/subscribers">
                                                        <FeatherIcon icon="send" />
                                                        <span className="pl-2">{this.props.t('event_subscribers')}</span>
                                                    </Link>
                                                </li>
                                            </div>
                                        </Collapse>
                                    </div>

                                </Collapse>
                            </span>

                        }
                        {!isAgent &&
                            <span>
                                <li className="nav-item">
                                    <span className="nav-link"
                                        onClick={() => this.setState({openSales: !openSales})}
                                        aria-controls="sales"
                                        aria-expanded={openSales}>
                                        <FeatherIcon icon="shopping-cart" />
                                        <span className="pl-2">{this.props.t('sales')}</span>
                                    </span>
                                </li>
                                <Collapse in={this.state.openSales}>
                                    <div className="pl-4" id="sales">

                                        <li className="nav-item">
                                            <span className="nav-link"
                                                onClick={() => this.setState({openPos: !openPos})}
                                                aria-controls="pos"
                                                aria-expanded={openPos}>
                                                <FeatherIcon icon="shopping-cart" />
                                                <span className="pl-2">{this.props.t('pos')}</span>
                                            </span>
                                        </li>
                                        <Collapse in={this.state.openPos}>
                                            <div className="pl-4" id="pos">
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/pos">
                                                        <FeatherIcon icon="list" />
                                                        <span className="pl-2">{this.props.t('liste')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/pos/ajout">
                                                        <FeatherIcon icon="plus-circle" />
                                                        <span className="pl-2">{this.props.t('add_pos')}</span>
                                                    </Link>
                                                </li>
                                            </div>
                                        </Collapse>
                                        <li className="nav-item">
                                            <span className="nav-link"
                                                onClick={() => this.setState({openItem: !openItem})}
                                                aria-controls="items"
                                                aria-expanded={openItem}>
                                                <FeatherIcon icon="star" />
                                                <span className="pl-2">{this.props.t('items')}</span>
                                            </span>
                                        </li>
                                        <Collapse in={this.state.openItem}>
                                            <div className="pl-4" id="items">
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/items">
                                                        <FeatherIcon icon="list" />
                                                        <span className="pl-2">{this.props.t('liste')}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" >
                                                    <Link className="nav-link" to="/dashboard/items/add">
                                                        <FeatherIcon icon="plus-circle" />
                                                        <span className="pl-2">{this.props.t('add_item')}</span>
                                                    </Link>
                                                </li>
                                            </div>
                                        </Collapse>
                                        <li className="nav-item">
                                            <span className="nav-link"
                                                onClick={() => this.setState({openRepartition: !openRepartition})}
                                                aria-controls="repartition"
                                                aria-expanded={openRepartition}>
                                                <FeatherIcon icon="command" />
                                                <span className="pl-2">{this.props.t('repartition')}</span>
                                            </span>
                                        </li>
                                        <Collapse in={this.state.openRepartition}>
                                            <div className="pl-4" id="repartition">

                                                <li className="nav-item">
                                                    <span className="nav-link"
                                                        onClick={() => this.setState({openRepartitions: !openRepartitions})}
                                                        aria-controls="repartitions"
                                                        aria-expanded={openRepartitions}>
                                                        <FeatherIcon icon="command" />
                                                        <span className="pl-2">{this.props.t('repartition_rules')}</span>
                                                    </span>
                                                </li>
                                                <Collapse in={this.state.openRepartitions}>
                                                    <div className="pl-4" id="repartitions">
                                                        <li className="nav-item" >
                                                            <Link className="nav-link" to="/dashboard/repartitions">
                                                                <FeatherIcon icon="list" />
                                                                <span className="pl-2">{this.props.t('liste')}</span>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item" >
                                                            <Link className="nav-link" to="/dashboard/repartitions/add">
                                                                <FeatherIcon icon="plus-circle" />
                                                                <span className="pl-2">{this.props.t('add_repartition')}</span>
                                                            </Link>
                                                        </li>
                                                    </div>
                                                </Collapse>
                                                <li className="nav-item">
                                                    <span className="nav-link"
                                                        onClick={() => this.setState({openBeneficiaire: !openBeneficiaire})}
                                                        aria-controls="beneficiaire"
                                                        aria-expanded={openBeneficiaire}>
                                                        <FeatherIcon icon="users" />
                                                        <span className="pl-2">{this.props.t('beneficiaires')}</span>
                                                    </span>
                                                </li>
                                                <Collapse in={this.state.openBeneficiaire}>
                                                    <div className="pl-4" id="beneficiaire">
                                                        <li className="nav-item" >
                                                            <Link className="nav-link" to="/dashboard/beneficiaires">
                                                                <FeatherIcon icon="list" />
                                                                <span className="pl-2">{this.props.t('liste')}</span>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item" >
                                                            <Link className="nav-link" to="/dashboard/beneficiaires/add">
                                                                <FeatherIcon icon="plus-circle" />
                                                                <span className="pl-2">{this.props.t('add_beneficiaire')}</span>
                                                            </Link>
                                                        </li>
                                                    </div>
                                                </Collapse>
                                            </div>

                                        </Collapse>
                                    </div>

                                </Collapse>
                            </span>

                        }
                        {!isAgent &&
                            <span>

                            </span>

                        }

                        <li className="nav-item" >
                            <Link className="nav-link" to="/dashboard/profil">
                                <FeatherIcon icon="user" />
                                <span className="pl-2">{this.props.t('my_profil')}</span>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <span className="nav-link"
                                onClick={() => this.setState({openCampaigns: !openCampaigns})}
                                aria-controls="sales"
                                aria-expanded={openCampaigns}>
                                <FeatherIcon icon="circle" />
                                <span className="pl-2">{this.props.t('campaigns')}</span>
                            </span>
                        </li> */}
                        {/* <Collapse in={this.state.openCampaigns}>

                            <div className="pl-4" id="items">
                                <li className="nav-item" >
                                    <Link className="nav-link" to="/dashboard/campaigns">
                                        <FeatherIcon icon="circle" />
                                        <span className="pl-2">{this.props.t('campaigns')}</span>
                                    </Link>
                                </li>
                                <li className="nav-item" >
                                    <Link className="nav-link" to="/dashboard/campaigns/add">
                                        <FeatherIcon icon="plus-circle" />
                                        <span className="pl-2">{this.props.t('add_campaign')}</span>
                                    </Link>
                                </li>
                            </div>
                        </Collapse> */}
                        <li className="nav-item" >
                            <Link className="nav-link" to="/dashboard/campaigns">
                                <FeatherIcon icon="circle" />
                                <span className="pl-2">{this.props.t('campaigns')}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}


export default withTranslation()(LeftMenu);
