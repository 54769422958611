import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Item extends Component {

  state = {
    _msg: '',
    campaigns: []
  }

  constructor(props, context) {
    super(props, context);
    this.handleDelete = this.handleDelete.bind(this);

  }

  componentDidMount() {
    console.log('componentDidMount')
    const Authorization = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authorization,
    };
    if ('token' in localStorage) {

      // get campaigns
      axios.get(`${BASE_URL}/api/campaigns`, { headers })
        .then(res => {
          console.log(res.data);
          const campaigns = res.data.campaigns;
          this.setState({ campaigns: campaigns });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  handleDelete = (campaignId) => {
    const Authorization = localStorage.getItem('token');
    const headers = {
      'Authorization': Authorization,
      'Content-Type': 'application/json'
    };

    axios.delete(`${BASE_URL}/api/campaigns/${campaignId}`, { headers })
      .then(response => {
        this.setState({
          show: true,
          _msg: "Delete Success"
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const editCampaignFormatter = (campaignId, row, rowIndex) => {
      return <div className="d-flex justify-content-around align-items-center">
        <Link to={`/dashboard/campaigns/${campaignId}/edit`} className="py-0">
          <FeatherIcon icon="edit" size="28" color="#36909b" />
        </Link>
        <Button variant="outline-link" className="ml-0"
          onClick={() => {
            if (window.confirm("Etes-vous sûre de vouloir supprimer cette Campagne ?")) {
              this.handleDelete(campaignId);
            }
          }}
        >
          <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
        </Button>
      </div>

    };

    const cols = [{
      dataField: 'label',
      text: this.props.t('forms.campaigns.label'),
      sort: true
    }, {
      dataField: 'description',
      text: this.props.t('forms.campaigns.description'),
    }, {
      dataField: 'targetAmount',
      text: this.props.t('forms.campaigns.targetAmount'),
    }, {
      dataField: 'active',
      text: this.props.t('forms.campaigns.active'),
    },
    {
      dataField: 'id',
      text: 'Actions',
      formatter: editCampaignFormatter
    },
    ];

    return (
      <div className="col-md-12">
        <h1 className="mb-3">{this.props.t('campaign')}</h1>
          <Link to="/dashboard/campaigns/add" className="float-right btn btn-link">
              <FeatherIcon icon="plus-circle" /> &nbsp;
              {this.props.t('forms.campaigns.add_campaign')}
          </Link>
        <div className="table-responsive">
          <BootstrapTable
            keyField='id'
            data={this.state.campaigns}
            columns={cols}
            pagination={paginationFactory()}
          />
        </div>
        <SweetAlert
          show={this.state.show}
          text={this.state._msg}
          type="success"
          onConfirm={() => this.setState({ show: false })}
        />
      </div>
    );
  }
}

export default withTranslation()(Item);