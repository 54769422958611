import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Link} from 'react-router-dom';
import {Button, Form, Row, Col} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import Select, {components} from 'react-select';
import {withTranslation} from 'react-i18next';

const IndicatorsContainer = (props) => {
    return (
        <div>
            <components.IndicatorsContainer {...props} />
        </div>
    );
}
class EditItem extends Component {

    state = {
        pos: [],
        items: [],
        repartitions: [],
        labelItem: "",
        descriptionItem: "",
        POS_id: "",
        item_id: "",
        itemId_form: "",
        options: [],
        DetailPOS: {},
        defaultValue: [],
        repartitionDisabled: {},
        tax: undefined,
        hasTax: false,
        taxValue: undefined,
        taxOptions: [
            {
                value: 0,
                label: "0%"
            },
            {
                value: 0.055,
                label: "5,5%"
            },
            {
                value: 0.10,
                label: "10%"
            },
            {
                value: 0.196,
                label: "19,6%"
            },
            {
                value: 0.2,
                label: "20%"
            }
        ]
    }

    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleHasTaxChange = this.handleHasTaxChange.bind(this);
        this.handleTaxChange = this.handleTaxChange.bind(this);

    }
    handleChange = (val) => {
        this.setState({pos: val});
    }
    handleTaxChange = (val) => {
        this.setState({tax: val.value});
    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };

        // get item
        axios.get(`${BASE_URL}/api/items/item/${this.props.match.params.idItem}`, {headers})
            .then(res => {
                const item = res.data.items;
                let tab = {};
                let _pOS = [];
                let _default = [];
                item.POs.forEach((pos_) => {
                    tab[pos_.idPOS] = {'stockItem': pos_.PItem.stockItem, 'prixItem': pos_.PItem.prixItem, 'priceExcludingTax': pos_.PItem.priceExcludingTax, 'consigne': pos_.PItem.consigne, 'campaign': pos_.PItem.campaign, 'repartition_id': pos_.PItem.repartition_id};
                    _pOS.push({'value': pos_.idPOS, 'label': pos_.labelPOS});
                    _default.push({'value': pos_.idPOS, 'label': pos_.labelPOS});
                })
                const itemHasTax = item.tax && item.tax !== '0' || false;
                this.setState({
                    itemId_form: item.idItem,
                    labelItem: item.labelItem,
                    descriptionItem: item.descriptionItem,
                    pos: _pOS,
                    tax: item.tax,
                    hasTax: item.tax && true || false,
                    DetailPOS: tab,
                    defaultValue: _default
                });


            })
            .catch(error => {
                console.log(error);
            });

        axios.get(`${BASE_URL}/api/pos?type=type&visible=true`, {headers})
            .then(res => {
                const items = res.data.pos;
                let options = items.map(function (item) {
                    return {
                        value: item.idPOS,
                        label: item.labelPOS,
                    };
                });
                this.setState({options: options});
            })
            .catch(error => {
                console.log(error);
            });
        // get repartitions
        axios.get(`${BASE_URL}/api/repartition`, {headers})
            .then(res => {
                const repartitions = res.data.repartitions;
                this.setState({
                    repartitions: repartitions,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }


    handleInputChange (e) {
        e.preventDefault();
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    };

    handleHasTaxChange (e) {
        const value = e.target.checked;
        this.setState({
            [e.target.name]: value
        });
    };

    handleSelectChange = (_pos) => (e) => {
        e.preventDefault();
        let ResPOS = Object.assign({}, this.state.DetailPOS);
        let repartitionDisabled = Object.assign({}, this.state.repartitionDisabled);
        let key = Object.keys(ResPOS);
        if (!key.includes(_pos.value.toString())) {
            ResPOS[_pos.value] = {};
        }
        // special rule for consigne field
        if (e.target.name === "consigne" && (e.target.value === 1 || e.target.value === '1')) {
            // ResPOS[_pos.value]['repartition_id'] = '';
            repartitionDisabled[_pos.value] = 1;
        } else {
            delete repartitionDisabled[_pos.value];
        }
        // special rule for campaign field
        if (e.target.name === "campaign" && (e.target.value === 1 || e.target.value === '1')) {
            // ResPOS[_pos.value]['repartition_id'] = '';
            repartitionDisabled[_pos.value] = 1;
        } else {
            delete repartitionDisabled[_pos.value];
        }
        ResPOS[_pos.value][e.target.name] = e.target.value;

        this.setState({
            DetailPOS: ResPOS,
            repartitionDisabled: repartitionDisabled
        });
    };

    roundTo (n, digits) {
        if (digits === undefined) {
            digits = 0;
        }

        var multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        return Math.round(n) / multiplicator;
    }
    parseValue (value) {
        return this.roundTo(value, 2).toFixed(2);
    }

    getTaxFreePrice = (item) => {
        if (item && item.prixItem && this.state.tax && this.state.hasTax) {
            return this.parseValue(parseFloat(item.prixItem) / (1 + parseFloat(this.state.tax)));
        }
        if (item && item.prixItem) {
            return this.parseValue(item.prixItem);
        }
        return undefined
    }

    handleSubmit = (itemId_form) => (e) => {
        /**
         * we update the ITEM (we pass the property clearPItems so the PItems will be sof deleted)
         * then we create the Pitems for the POS
         */
        e.preventDefault();

        const {labelItem, descriptionItem, tax} = this.state;
        const getTaxFreePrice = this.getTaxFreePrice;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        this.setState({
            repartitionDisabled: {}
        });
        // axios.delete(`${BASE_URL}/api/items/item/${itemId_form}`, { headers })
        axios.put(`${BASE_URL}/api/items/item/${itemId_form}`, {labelItem, descriptionItem, tax, clearPItems: true}, {headers})
            .then(resp => {
                const item_id = itemId_form;
                this.setState({item_id: item_id});
                // pitem

                let ResPOS = Object.assign({}, this.state.DetailPOS);
                const pItemsRepartitions = {};
                if (this.state.pos.length > 0) {
                    for (const posId in ResPOS) {
                        const pItem = ResPOS[posId];
                        if (('repartition_id' in pItem) && (pItem.consigne === 1 || pItem.consigne === '1')) {
                            console.log('Repartition will be ignored');
                            pItemsRepartitions[posId] = pItem;
                        }
                        if (('repartition_id' in pItem) && (pItem.campaign === 1 || pItem.campaign === '1')) {
                            console.log('Repartition will be ignored');
                            pItemsRepartitions[posId] = pItem;
                        }
                    }
                }
                this.setState({repartitionDisabled: pItemsRepartitions})
                let key = Object.keys(ResPOS);
                let promise = [];
                this.state.pos.forEach(_pos => {
                    if (key.includes(_pos.value.toString())) {
                        let params = {
                            prixItem: ResPOS[_pos.value]["prixItem"],
                            priceExcludingTax: getTaxFreePrice(ResPOS[_pos.value]),
                            stockItem: ResPOS[_pos.value]["stockItem"],
                            repartition_id: ((ResPOS[_pos.value]["consigne"] === '1' || ResPOS[_pos.value]["consigne"] === 1) || (ResPOS[_pos.value]["campaign"] === '1' || ResPOS[_pos.value]["campaign"] === 1)) ? '' : ResPOS[_pos.value]["repartition_id"],
                            consigne: ResPOS[_pos.value]["consigne"],
                            campaign: ResPOS[_pos.value]["campaign"]
                        }
                        promise.push(axios.post(`${BASE_URL}/api/items/${item_id}/pos/${_pos.value}`, params, {headers}));
                    }

                });
                Promise.all(promise)
                    .then(response => {
                        this.setState({
                            show: true,
                            pos: [],
                            labelItem: "",
                            descriptionItem: ""
                        });
                        this.props.history.push("/dashboard/items");
                    })
                    .catch(error => {
                        throw (error);
                    });

            });
        // .then(resp => {
        //     // items
        //     axios.post(`${BASE_URL}/api/items/`, { labelItem, descriptionItem }, { headers })
        //         .then(response => {
        //             const item_id = response.data.data.idItem;
        //             this.setState({ item_id: item_id });
        //             // pitem

        //             let ResPOS = Object.assign( {}, this.state.DetailPOS );
        //             const pItemsRepartitions = {};
        //             if (this.state.pos.length > 0 ) {
        //                 for (const posId in ResPOS) {
        //                     const pItem = ResPOS[posId];
        //                     if (('repartition_id' in pItem) && (pItem.consigne === 1|| pItem.consigne === '1' )) {
        //                         console.log('Repartition will be ignored');
        //                         pItemsRepartitions[posId] = pItem;
        //                     }
        //                 }
        //             }
        //             this.setState({repartitionDisabled: pItemsRepartitions})
        //             let key = Object.keys(ResPOS);
        //             let promise = [];
        //             this.state.pos.forEach(_pos => {
        //                 if ( key.includes(_pos.value.toString()) ){
        //                     let params = {
        //                         prixItem: ResPOS[_pos.value]["prixItem"],
        //                         stockItem: ResPOS[_pos.value]["stockItem"],
        //                         repartition_id: (ResPOS[_pos.value]["consigne"] === '1' || ResPOS[_pos.value]["consigne"] === 1) ? '' : ResPOS[_pos.value]["repartition_id"],
        //                         consigne: ResPOS[_pos.value]["consigne"]
        //                     }
        //                     promise.push(axios.post(`${BASE_URL}/api/items/${item_id}/pos/${_pos.value}`, params , { headers }));
        //                 }

        //             });
        //             Promise.all(promise)
        //             .then(response => {
        //                 this.setState({ 
        //                     show: true,
        //                     pos: [],
        //                     labelItem: "",
        //                     descriptionItem: ""
        //                 });
        //                 this.props.history.push("/dashboard/items");
        //             })
        //             .catch(error => {
        //                 throw (error);
        //             });

        //         })
        //         .catch(error => {
        //             throw (error);
        //         });
        // });
    };

    render () {
        return (
            <div className="col-md-12">
                <h1>{this.props.t('edit_item')}</h1>
                <div className="row justify-content-left">
                    <div className="col-md-12">
                        <Form className="py-3" onSubmit={this.handleSubmit(this.state.itemId_form)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group controlId="formLabel">
                                        <Form.Label>Label</Form.Label>
                                        <Form.Control type="text" placeholder="Label" value={this.state.labelItem} name="labelItem" onChange={this.handleInputChange} required />
                                    </Form.Group>
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" rows="3" value={this.state.descriptionItem} name="descriptionItem" onChange={this.handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formPOS">
                                        <Form.Label>POS</Form.Label>
                                        {
                                            (this.state.defaultValue.length > 0) ?
                                                <Select
                                                    async
                                                    withAll={true}
                                                    closeMenuOnSelect={false}
                                                    options={this.state.options}
                                                    defaultValue={(this.state.defaultValue)}
                                                    components={IndicatorsContainer}
                                                    placeholder={this.props.t('choose_pos')}
                                                    isMulti
                                                    onChange={this.handleChange}
                                                />
                                                :
                                                <span></span>

                                        }
                                    </Form.Group>
                                    <Form.Group controlId="formHasTax">
                                        <Form.Check type="checkbox"
                                            checked={this.state.hasTax}
                                            onChange={this.handleHasTaxChange} name="hasTax" label={this.props.t('forms.items.hasTax')} />
                                        {/* <p className="text-small text-left text-muted"><i>{ this.props.t('forms.item.hasTax') }</i></p> */}
                                    </Form.Group>
                                    {
                                        (this.state.hasTax) ?
                                            <Select
                                                async
                                                withAll={true}
                                                closeMenuOnSelect={true}
                                                options={this.state.taxOptions}
                                                value={
                                                    this.state.taxOptions.filter(option =>
                                                        option.value === this.state.tax)
                                                }
                                                defaultValue={
                                                    this.state.taxOptions.filter(option =>
                                                        option.value === 0)
                                                }
                                                components={IndicatorsContainer}
                                                placeholder={this.props.t('forms.items.choose_tax')}
                                                onChange={this.handleTaxChange}
                                            />
                                            :
                                            <span></span>

                                    }
                                </div>
                                <div className="col-md-8">
                                    {this.state.pos.map((_pos, i) =>

                                        <Form.Group key={i} controlId="formPOS">
                                            <Form.Label>POS {_pos.label}</Form.Label>
                                            <Row>
                                                <Col>
                                                    <Form.Control type="number" step="0.1" placeholder={this.props.t('prix') + ' ' + _pos.label}
                                                        value={(this.state.DetailPOS[_pos.value]) ? this.state.DetailPOS[_pos.value].prixItem : ''}
                                                        name="prixItem" onChange={this.handleSelectChange(_pos)} required />
                                                </Col>
                                                <Col>
                                                    <Form.Control type="number" placeholder={this.props.t('prix_ht') + ' ' + _pos.label}
                                                        value={this.getTaxFreePrice(this.state.DetailPOS[_pos.value])}
                                                        name="priceExcludingTax" disabled onChange={this.handleSelectChange(_pos)} />
                                                </Col>
                                                <Col>
                                                    <Form.Control type="number" placeholder={this.props.t('stock') + ' ' + _pos.label}
                                                        value={(this.state.DetailPOS[_pos.value]) ? this.state.DetailPOS[_pos.value].stockItem : ''}
                                                        name="stockItem" onChange={this.handleSelectChange(_pos)} required />
                                                </Col>
                                                <Col>
                                                    <Form.Control as="select" name="repartition_id"
                                                        value={(this.state.DetailPOS[_pos.value]) ? this.state.DetailPOS[_pos.value].repartition_id : ""}
                                                        onChange={this.handleSelectChange(_pos)}>
                                                        <option value="">{this.props.t('choose_repartition')}</option>
                                                        {this.state.repartitions.map((repartition, j) =>
                                                            <option key={j} value={repartition.id}>{repartition.nom}</option>
                                                        )}
                                                    </Form.Control>
                                                    {(this.state.repartitionDisabled && _pos.value in this.state.repartitionDisabled) ? <p className="text-right text-danger">{this.props.t(`error.repartition.ignore`)}</p> : <p></p>}
                                                </Col>
                                                <Col>
                                                    {
                                                        (this.state.DetailPOS[_pos.value]) ?
                                                            (this.state.DetailPOS[_pos.value].consigne === true) ?
                                                                <Form.Control as="select" defaultValue="1" name="consigne" onChange={this.handleSelectChange(_pos)} required>
                                                                    <option value="1">{this.props.t('forms.pitems.deposit')}</option>
                                                                    <option value="0">{this.props.t('forms.pitems.non_deposit')}</option>
                                                                </Form.Control>
                                                                :
                                                                <Form.Control as="select" defaultValue="0" name="consigne" onChange={this.handleSelectChange(_pos)} required>
                                                                    <option value="1">{this.props.t('forms.pitems.deposit')}</option>
                                                                    <option value="0">{this.props.t('forms.pitems.non_deposit')}</option>
                                                                </Form.Control>
                                                            :
                                                            <Form.Control as="select" defaultValue="0" name="consigne" onChange={this.handleSelectChange(_pos)} required>
                                                                <option value="1">{this.props.t('forms.pitems.deposit')}</option>
                                                                <option value="0">{this.props.t('forms.pitems.non_deposit')}</option>
                                                            </Form.Control>
                                                    }
                                                </Col>
                                                <Col>
                                                    {
                                                        (this.state.DetailPOS[_pos.value]) ?
                                                            (this.state.DetailPOS[_pos.value].campaign === true) ?
                                                                <Form.Control as="select" defaultValue="1" name="campaign" onChange={this.handleSelectChange(_pos)} required>
                                                                    <option value="1">{this.props.t('forms.pitems.campaign')}</option>
                                                                    <option value="0">{this.props.t('forms.pitems.non_campaign')}</option>
                                                                </Form.Control>
                                                                :
                                                                <Form.Control as="select" defaultValue="0" name="campaign" onChange={this.handleSelectChange(_pos)} required>
                                                                    <option value="1">{this.props.t('forms.pitems.campaign')}</option>
                                                                    <option value="0">{this.props.t('forms.pitems.non_campaign')}</option>
                                                                </Form.Control>
                                                            :
                                                            <Form.Control as="select" defaultValue="0" name="campaign" onChange={this.handleSelectChange(_pos)} required>
                                                                <option value="1">{this.props.t('forms.pitems.campaign')}</option>
                                                                <option value="0">{this.props.t('forms.pitems.non_campaign')}</option>
                                                            </Form.Control>
                                                    }
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                    )}
                                </div>
                            </div>
                            <div className="mt-4 text-left">
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                                <Link to="/dashboard/items" className="mr-2 btn btn-link">{this.props.t('retour_liste')}</Link>
                            </div>
                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Insertion avec succès"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />
            </div>
        );
    }
}

export default withTranslation()(EditItem);