import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../../assets/css/login.css';
import {BASE_URL} from '../../config';
import SweetAlert from 'sweetalert2-react';
import {withTranslation} from 'react-i18next';

class ChangePass extends Component {
    state = {
        token: "",
        password: "",
        confirmPassword: "",
        token_pass: ""
    };
    constructor (props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount () {
        if (this.props.isAuth === true) {
            this.props.history.push("/dashboard");
        }
        // console.log('KEYYYYYYYY / / / / ', this.props.match.params.key)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            msg_ko: ""
        });
    };
    handleSubmit = e => {
        e.preventDefault();
        let token_pass = this.props.match.params.key;

        // this.setState({ token_pass: this.props.match.params.key });
        const {password, confirmPassword} = this.state;
        if (this.state.password.trim() && this.state.confirmPassword.trim()) {
            if (password !== confirmPassword) {
                this.setState({show: true, msg_ko: this.props.t('password_missmatch')});
            } else {
                axios.put(`${BASE_URL}/api/auth/pass`, {password: password, token_pass: token_pass})
                    .then(response => {
                        if (response.data.status === "success") {
                            this.setState({show: true, msg_ko: this.props.t('password_reset_success_msg')});
                            this.props.history.push("/login");
                        }
                    })
                    .catch(error => {
                        throw (error);
                    });
            }
        }
    };
    render () {
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-10">
                            <h1 className="text-center my-3">{this.props.t('password_reset')}</h1>
                            <Form className="py-3" onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>{this.props.t('new_password')}</Form.Label>
                                    <Form.Control type="password" autoComplete="off" placeholder={this.props.t('new_password')} name="password" onChange={this.handleInputChange} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{this.props.t('confirm_new_password')}</Form.Label>
                                    <Form.Control type="password" autoComplete="off" placeholder={this.props.t('confirm_new_password')} name="confirmPassword" onChange={this.handleInputChange} required />
                                </Form.Group>
                                <div className="text-center pt-3">
                                    <Button variant="outline-dark" type="submit">{this.props.t('submit')}</Button>
                                </div>
                            </Form>
                            <hr className="w-100" />
                            <div className="d-flex justify-content-around py-3">
                                <Link to="/login">{this.props.t('btn_login')}</Link>
                                <span> | </span>
                                <Link to="/register">{this.props.t('signup')}</Link>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        text={this.state.msg_ko}
                        onConfirm={() => this.setState({show: false})}
                    />
                </div>
            </section>
        );
    }
}

export default withTranslation()(ChangePass);
