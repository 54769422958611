import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Button, Form, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import {withTranslation} from 'react-i18next';
// import DataTable from 'react-data-table-component';
import {ReactSortable} from "react-sortablejs";

class EditPos extends Component {

    state = {
        items: [],
        repartitions: [],
        idPOS: "",
        labelPOS: "",
        descriptionPOS: "",
        deviceIDPOS: "",
        repartition_id: "",
        _msg: "",
        is_bank: false,
        visible: true,
        payment_mode_pos: false,
        payment_mode_external_pos: false,
        payment_mode_cash: false,
        payment_mode_cashless: false,
        hasDefaultBeneficiary: true
    }

    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        axios.get(`${BASE_URL}/api/pos/${this.props.match.params.idPOS}`, {headers})
            .then(res => {
                const posById = res.data.pos;
                const items = res.data.pos.Items;

                this.setState({
                    idPOS: posById.idPOS,
                    labelPOS: posById.labelPOS,
                    descriptionPOS: posById.descriptionPOS,
                    deviceIDPOS: posById.deviceIDPOS,
                    repartition_id: posById.Repartition !== null ? posById.Repartition.id : "",
                    items: items,
                    is_bank: posById.is_bank,
                    visible: posById.visible,
                    payment_mode_pos: posById.payment_mode_pos,
                    payment_mode_external_pos: posById.payment_mode_external_pos,
                    payment_mode_cash: posById.payment_mode_cash,
                    payment_mode_cashless: posById.payment_mode_cashless
                });
            })
            .catch(error => {
                console.log(error);
            });

        // get repartitions
        axios.get(`${BASE_URL}/api/repartition`, {headers})
            .then(res => {
                const repartitions = res.data.repartitions;
                this.setState({
                    repartitions: repartitions,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    componentDidUpdate (_, prevState) {
        //check if items order has changed
        const stateItemIds = this.state.items.map((item => item.idItem))
        const prevStateItemIds = prevState.items.map((item => item.idItem))
        if ((prevStateItemIds.toString() !== '') && (stateItemIds.toString() !== prevStateItemIds.toString())) {
            this.handleOrderChange();
        }
    };

    handleInputChange = event => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'payment_mode_pos') {
            // get value of the payment_mode_external_pos
            if (this.state.payment_mode_external_pos && this.state.payment_mode_external_pos === true) {
                value = false;
            }
        }
        if (name === 'payment_mode_external_pos') {
            // get value of the payment_mode_pos
            if (this.state.payment_mode_pos && this.state.payment_mode_pos === true) {
                value = false;
            }
        }
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (id) => e => {
        e.preventDefault();
        if (this.state.labelPOS.trim()) {
            const {
                labelPOS, descriptionPOS,
                deviceIDPOS, repartition_id,
                is_bank,
                visible,
                payment_mode_pos, payment_mode_external_pos,
                payment_mode_cash, payment_mode_cashless
            } = this.state;
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
            };
            axios.put(`${BASE_URL}/api/pos/${id}`, {
                labelPOS: labelPOS.trim(),
                descriptionPOS: descriptionPOS.trim(),
                deviceIDPOS: deviceIDPOS.trim(),
                is_bank: is_bank,
                visible: visible,
                payment_mode_pos,
                payment_mode_external_pos,
                payment_mode_cash,
                payment_mode_cashless
            }, {headers})
                .then(response => {
                    // if(repartition_id && repartition_id != '') {
                    axios.put(`${BASE_URL}/api/repartition/pos/${id}`, {repartition_id}, {headers})
                        .then(response => {
                            this.setState({
                                show: true,
                                _msg: "Update Success"
                            });
                        })
                        .catch(error => {
                            throw (error);
                        });
                    // }
                })
                .catch(error => {
                    throw (error);
                });
        }
    };

    handleDelete = (idItem) => {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Authorization': Authorization,
            'Content-Type': 'application/json'
        };

        axios.delete(`${BASE_URL}/api/items/${idItem}/pos/${this.props.match.params.idPOS}`, {headers})
            .then(res => {
                this.setState({
                    show: true,
                    _msg: "Delete Success"
                });
                axios.get(`${BASE_URL}/api/pos/${this.props.match.params.idPOS}`, {headers})
                    .then(res => {
                        const posById = res.data.pos;
                        const items = res.data.pos.Items;

                        this.setState({
                            idPOS: posById.idPOS,
                            labelPOS: posById.labelPOS,
                            descriptionPOS: posById.descriptionPOS,
                            deviceIDPOS: posById.deviceIDPOS,
                            items: items
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleReset = () => {
        this.setState({
            labelPOS: "",
            descriptionPOS: "",
            deviceIDPOS: ""
        });
    };

    handleOrderChange = () => {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Authorization': Authorization,
            'Content-Type': 'application/json'
        };

        const items = this.state.items.map(((item, index) => {
            return {idPItem: item.PItem.idPItem, idItem: item.idItem, order: index}
        }))

        axios.post(`${BASE_URL}/api/pos/${this.props.match.params.idPOS}/items/reorder`, {items}, {headers})
            .then(res => {
                // const items = res.data.pos.Items;

                // this.setState({
                //     items: items
                // });
                console.log('OK')
            })
            .catch(error => {
                console.log(error);
            });
    }

    render () {

        // const columns = [
        //     {
        //         name: <b>#</b>,
        //         selector: 'idItem',
        //         sortable: true,
        //     },
        //     {
        //         name: <b>Label</b>,
        //         selector: 'labelItem',
        //         sortable: true,
        //     },
        //     {
        //         name: <b>Description</b>,
        //         selector: 'descriptionItem',
        //     },
        //     {
        //         name: <b>{this.props.t('prix')}</b>,
        //         selector: 'PItem.prixItem',
        //         sortable: true,
        //     },
        //     {
        //         name: <b>{this.props.t('stock')}</b>,
        //         selector: 'PItem.stockItem',
        //         sortable: true,
        //     },
        //     {
        //         name: <b>{this.props.t('consigne')}</b>,
        //         cell: row =>
        //             <b className="d-flex align-items-center">
        //                 {row.PItem.consigne === true ? "Oui" : "Non"}
        //             </b>
        //         ,
        //         sortable: false,
        //     },
        //     {
        //         name: 'Actions',
        //         selector: 'idItem',
        //         cell: row =>
        //             <div className="d-flex align-items-center">
        //                 <Link to={"/dashboard/items/" + row.idItem + "/" + this.props.match.params.idPOS} className="py-0">
        //                     <FeatherIcon icon="edit" size="28" color="#36909b" />
        //                 </Link>
        //                 <Button variant="outline-link" className="ml-5"
        //                     onClick={() => {
        //                         if (window.confirm("Etes-vous sûre de vouloir supprimer cet Item ?")) {
        //                             this.handleDelete(row.idItem);
        //                         }
        //                     }}
        //                 >
        //                     <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
        //                 </Button>
        //             </div>
        //         ,
        //         ignoreRowClick: true,
        //         allowOverflow: true,
        //         button: true,
        //     }
        // ];

        // // style table
        // const TableTheme = {
        //     title: {
        //         fontColor: '#747474',
        //         fontSize: '30px'
        //     },
        //     header: {
        //         fontSize: '16px',
        //         fontWeight: '700',
        //         fontColor: '#212529'
        //     },
        //     rows: {
        //         fontSize: '16px',
        //         fontColor: '#212529',
        //         borderWidth: '1px',
        //         borderColor: '#dee2e6',
        //     }
        // }

        // let table_header = (
        //     <div className="d-flex justify-content-between w-100">
        //         <h2>{this.props.t('item_attached')}</h2>

        //         <Link to="/dashboard/items/add" className="float-right btn btn-link">
        //             <FeatherIcon icon="plus-circle" /> &nbsp;
        //             {this.props.t('add_item')}
        //         </Link>
        //     </div>
        // );

        return (
            <div className="col-md-12">
                <h1 className="mb-2">{this.props.t('edit_pos')}</h1>
                <div className="row">
                    <div className="col-md-12">
                        <Form className="py-3" onSubmit={this.handleSubmit(this.state.idPOS)}>
                            <Row>
                                <Col>
                                    <Form.Label>{this.props.t('forms.pos.label')}</Form.Label>
                                    <Form.Control type="text" placeholder="Label" name="labelPOS" value={this.state.labelPOS} onChange={this.handleInputChange} required />
                                </Col>
                                <Col>
                                    <Form.Label>{this.props.t('forms.pos.description')}</Form.Label>
                                    <Form.Control type="text" placeholder="Description" name="descriptionPOS" value={this.state.descriptionPOS} onChange={this.handleInputChange} />
                                </Col>
                                <Col>
                                    <Form.Label>{this.props.t('forms.pos.device')}</Form.Label>
                                    <Form.Control type="text" placeholder="Device" name="deviceIDPOS" value={this.state.deviceIDPOS} onChange={this.handleInputChange} />
                                    <p className="text-small text-left text-muted"><i>{this.props.t('forms.pos.device_desc')}</i></p>
                                </Col>
                                <Col>
                                    <Form.Label>{this.props.t('repartition')}</Form.Label>
                                    <Form.Control as="select" name="repartition_id" value={this.state.repartition_id} onChange={this.handleInputChange}>
                                        <option value="">{this.props.t('choose_repartition')}</option>
                                        {this.state.repartitions.map((repartition, index) =>
                                            <option key={index} value={repartition.id}>{repartition.nom}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formIsBank">
                                        <Form.Check type="checkbox"
                                            checked={this.state.is_bank}
                                            onChange={this.handleInputChange} name="is_bank" label={this.props.t('forms.pos.is_bank')} />
                                        <p className="text-small text-left text-muted"><i>{this.props.t('forms.pos.is_bank_desc')}</i></p>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formIsVisible">
                                        <Form.Check type="checkbox"
                                            checked={this.state.visible}
                                            onChange={this.handleInputChange} name="visible" label={this.props.t('forms.pos.visible')} />
                                        <p className="text-small text-left text-muted"><i>{this.props.t('forms.pos.visible_desc')}</i></p>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>

                                        <Row>
                                            <Col>
                                                <Form.Label>{this.props.t('forms.pos.payment_mode_label')}</Form.Label>
                                                <Form.Check type="checkbox" checked={this.state.payment_mode_pos} onChange={this.handleInputChange} name="payment_mode_pos" label={this.props.t('forms.pos.payment_mode_pos')} />
                                                <Form.Check type="checkbox" checked={this.state.payment_mode_external_pos} onChange={this.handleInputChange} name="payment_mode_external_pos" label={this.props.t('forms.pos.payment_mode_external_pos')} />
                                                <p className="text-small text-left text-muted"><i>{this.props.t('forms.pos.payment_mode_condition')}</i></p>
                                            </Col>
                                            <Col>
                                                <Form.Check type="checkbox" checked={this.state.payment_mode_cash} onChange={this.handleInputChange} name="payment_mode_cash" label={this.props.t('forms.pos.payment_mode_cash')} />
                                                <Form.Check type="checkbox" checked={this.state.payment_mode_cashless} onChange={this.handleInputChange} name="payment_mode_cashless" label={this.props.t('forms.pos.payment_mode_cashless')} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="mt-4 text-right">
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                                <Link to="/dashboard/pos" className="mr-2 btn btn-link">{this.props.t('retour_liste')}</Link>
                            </div>
                        </Form>
                        <hr />


                        <div className="table-responsive">
                            {/* <DataTable
                                title={table_header}
                                columns={columns}
                                data={this.state.items}
                                pagination={true}
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                customTheme={TableTheme}
                                defaultSortField="idItem"
                                defaultSortAsc={false}
                            /> */}
                            <div className="d-flex justify-content-between w-100 mb-3">
                                <h2>{this.props.t('item_attached')}</h2>

                                <Link to="/dashboard/items/add" className="float-right btn btn-link">
                                    <FeatherIcon icon="plus-circle" /> &nbsp;
                                    {this.props.t('add_item')}
                                </Link>
                            </div>
                            <table className='w-100' style={{tableLayout: 'fixed'}}>
                                <tr className='border-bottom'>
                                    <th className='py-3 pl-3'>#</th>
                                    <th>Label</th>
                                    <th>Description</th>
                                    <th>{this.props.t('prix')}</th>
                                    <th>{this.props.t('stock')}</th>
                                    <th>{this.props.t('consigne')}</th>
                                    <th>{this.props.t('campaign')}</th>
                                    <th className='font-weight-normal'>Actions</th>
                                </tr>
                                <ReactSortable
                                    list={this.state.items}
                                    setList={(newItems) => this.setState({items: newItems})}
                                    tag='tbody'
                                >
                                    {this.state.items.map((item) => (
                                        <tr key={item.idItem} className='border-bottom' style={{cursor: 'pointer'}}>
                                            <td className='py-3 pl-3'>{item.idItem}</td>
                                            <td className='pr-3' style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{item.labelItem}</td>
                                            <td className='pr-3' style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{item.descriptionItem}</td>
                                            <td>{item.PItem.prixItem}</td>
                                            <td >{item.PItem.stockItem}</td>
                                            <td >
                                                <b>{item.PItem.consigne === true ? "Oui" : "Non"}</b>
                                            </td>
                                            <td >
                                                <b>{item.PItem.campaign === true ? "Oui" : "Non"}</b>
                                            </td>
                                            <td >
                                                <div className="d-flex align-items-center">
                                                    <Link to={"/dashboard/items/" + item.idItem + "/" + this.props.match.params.idPOS} className="py-0">
                                                        <FeatherIcon icon="edit" size="28" color="#36909b" />
                                                    </Link>
                                                    <Button variant="outline-link" className="ml-5"
                                                        onClick={() => {
                                                            if (window.confirm("Etes-vous sûre de vouloir supprimer cet Item ?")) {
                                                                this.handleDelete(item.idItem);
                                                            }
                                                        }}
                                                    >
                                                        <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </ReactSortable>
                            </table>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text={this.state._msg}
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />
            </div>
        );
    }
}

export default withTranslation()(EditPos)