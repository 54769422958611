import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {withTranslation} from 'react-i18next';
import {Button, Form, Row, Col} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select, {components} from 'react-select';
import moment from 'moment';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {fr, enGB} from 'date-fns/locale'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";
import SweetAlert from 'sweetalert2-react';
import isEmail from 'validator/lib/isEmail';
registerLocale('fr', fr);
registerLocale('en', enGB);

class EventConfig extends Component {

    constructor (props, context) {
        super(props, context);
        this.state = {
            configs: [],
            debut: new Date(),
            fin: new Date(),
            date_from: "",
            date_from_id: "",
            date_to: "",
            date_to_id: "",
            dateToError: null,
            cashlessError: null,
            fairlyScoreActiveError: null,
            cashless: "",
            cashless_id: "",
            selectedCashlessValue: null,
            merchant_code: "",
            refund_password: "",
            legal_name: "",
            legal_zip_code: "",
            legal_city: "",
            legal_email: "",
            legal_number: "",
            fairly_score_active: "",
            fairly_score_project_id: "",
        };
        this.handleSubmitDateFrom = this.handleSubmitDateFrom.bind(this);
        this.handleSubmitDateTo = this.handleSubmitDateTo.bind(this);
        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.setInitialDate = this.setInitialDate.bind(this);
        this.handleSubmitCashless = this.handleSubmitCashless.bind(this);
        this.handleSubmitFairlyScoreActive = this.handleSubmitFairlyScoreActive.bind(this);
        this.handleCashlessChange = this.handleCashlessChange.bind(this);
        this.handleFairlyScoreActiveChange = this.handleFairlyScoreActiveChange.bind(this);
        this.handleMerchantCodeChange = this.handleMerchantCodeChange.bind(this);
        this.handleRefundPasswordChange = this.handleRefundPasswordChange.bind(this);

    }
    handleDateFromChange (date) {
        if (date || date !== "") {
            this.setState({
                date_from: date
            });
        }
    }
    handleDateToChange (date) {
        if (date || date !== "") {
            this.setState({
                date_to: date
            });
        }
    }
    handleCashlessChange = (selectValue) => {
        this.setState({cashless: selectValue.value, selectedCashlessValue: selectValue});
    }
    handleFairlyScoreActiveChange = (selectValue) => {
        this.setState({fairlyScoreActive: selectValue.value, selectedFairlyScoreActiveValue: selectValue});
    }
    handleMerchantCodeChange = (selectValue) => {
        this.setState({merchant_code: selectValue.value});
    }
    handleRefundPasswordChange = (selectValue) => {
        this.setState({refund_password: selectValue.value});
    }
    setInitialDate () {
        const {date_from, date_to} = this.state;
        console.log('set init date', date_from);
        if (date_to === "" && (date_from || date_from !== "")) {
            this.setState({
                date_to: date_from
            });
        }
    }
    handleEndChange (date) {
        this.setState({
            fin: date
        });
    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.get(`${BASE_URL}/api/config`, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    const configs = res.data.config;
                    configs.forEach((item) => {
                        if (item.key === "date_from") {
                            this.setState({
                                date_from: new Date(item.value),
                                date_from_id: item.id,
                            });
                        } else if (item.key === "date_to") {
                            this.setState({
                                date_to: new Date(item.value),
                                date_to_id: item.id
                            });
                        } else if (item.key === "cashless") {
                            let selectedCashlessValue;
                            // get value and set selected value
                            if (item.value === 0 || item.value === '0' || item.value === false) {
                                selectedCashlessValue = {value: "0", label: this.props.t('no')};
                            } else if (item.value === 1 || item.value === '1' || item.value === true) {
                                selectedCashlessValue = {value: "1", label: this.props.t('yes')};
                            }
                            this.setState({
                                cashless: item.value,
                                cashless_id: item.id,
                                selectedCashlessValue: selectedCashlessValue
                            });
                        } else if (item.key === "fairly_score_active") {
                            let selectedFairlyScoreActiveValue;
                            // get value and set selected value
                            if (item.value === 0 || item.value === '0' || item.value === false) {
                                selectedFairlyScoreActiveValue = {value: "0", label: this.props.t('no')};
                            } else if (item.value === 1 || item.value === '1' || item.value === true) {
                                selectedFairlyScoreActiveValue = {value: "1", label: this.props.t('yes')};
                            }
                            this.setState({
                                fairlyScoreActiveValue: item.value,
                                fairlyScoreActiveValue_id: item.id,
                                selectedFairlyScoreActiveValue: selectedFairlyScoreActiveValue
                            });
                        } else if (item.key === "merchant_code") {
                            this.setState({
                                merchant_code: item.value
                            });
                        } else if (item.key === "refund_password") {
                            this.setState({
                                refund_password: item.value
                            });
                        } else {
                            this.setState({
                                [item.key]: item.value
                            });
                        }
                    });
                    console.log(this.state);
                    // if (!this.state.date_from) {
                    //     this.state.date_from = new Date();
                    // }
                }
            })
            .catch(error => {
                console.log(error);
            });

    }

    handleInputChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log("NAME", e.target.value);
    };

    handleSubmitDateFrom = (id) => (e) => {
        e.preventDefault();
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        const {date_from} = this.state;
        axios.post(`${BASE_URL}/api/config/`, {key: 'date_from', value: date_from}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleSubmitDateTo = (id) => (e) => {
        e.preventDefault();
        const {date_from, date_to} = this.state;

        const dateFrom = moment(date_from);
        const dateTo = moment(date_to);
        if (date_to && dateTo && date_from && dateFrom && (dateTo.unix() < dateFrom.unix())) {

            this.setState({
                dateToError: 'error.fin.incompatible'
            });
            throw ('Incompatible Dates');
        }
        this.setState({
            dateToError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, {key: 'date_to', value: date_to}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleSubmitCashless = (id) => (e) => {
        e.preventDefault();
        const {cashless} = this.state;
        if (cashless === "" || cashless === null || cashless === undefined) {

            this.setState({
                cashlessError: 'error.cashless.notSelected'
            });
            throw ('cashless Not Selected');
        }
        this.setState({
            cashlessError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, {key: 'cashless', value: cashless.toString()}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleSubmitFairlyScoreActive = (id) => (e) => {
        e.preventDefault();
        const {fairlyScoreActive} = this.state;
        if (fairlyScoreActive === "" || fairlyScoreActive === null || fairlyScoreActive === undefined) {

            this.setState({
                fairlyScoreActiveError: 'error.fairlyScoreActive.notSelected'
            });
            throw ('Fairly Score Active Not Selected');
        }
        this.setState({
            fairlyScoreActiveError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, {key: 'fairly_score_active', value: fairlyScoreActive.toString()}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleMerchantCode = (id) => (e) => {
        e.preventDefault();
        const {merchant_code} = this.state;
        if (merchant_code === "" || merchant_code === null || merchant_code === undefined) {

            this.setState({
                merchantCodeError: 'error.merchant_code.notNull'
            });
            throw ('merchant_code Not Null');
        }
        this.setState({
            merchantCodeError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, {key: 'merchant_code', value: merchant_code.toString()}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleRefundPassword = (id) => (e) => {
        e.preventDefault();
        const {refund_password} = this.state;
        if (refund_password === "" || refund_password === null || refund_password === undefined) {

            this.setState({
                merchantCodeError: 'error.refund_password.notNull'
            });
            throw ('refund_password Not Null');
        }
        this.setState({
            merchantCodeError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, {key: 'refund_password', value: refund_password.toString()}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }
    handleConfigValue = (key, value) => (e) => {
        e.preventDefault();
        console.log('handleConfigValue >>', key, value);
        if (key === 'legal_email') {
            //check if is a valid email
            if (!isEmail(value)) {
                this.setState({
                    legal_emailError: 'error.legal_email.notValid'
                });
                throw ('Not Valid Email');

            }
        }
        this.setState({
            legal_emailError: null
        });
        if (value === "" || value === null || value === undefined) {
            return;
        }
        this.setState({
            merchantCodeError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, {key, value: value.toString()}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({show: true});
                }
            })
            .catch(error => {
                throw (error);
            })
    }

    render () {

        return (
            <section>
                <div className="col-md-12">
                    <h1>{this.props.t('event_settings_title')}</h1>
                    <hr />
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('event_dates_title')}</h3>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={12}>
                                <Form.Label>{this.props.t('dateFrom')}</Form.Label>
                                <br />
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.date_from}
                                    onChange={this.handleDateFromChange}
                                    showTimeSelect
                                    // dateFormat="Pp"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                    locale="en"
                                    popperPlacement="auto-right"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["auto-right"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitDateFrom(this.state.date_from_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                            <Col xs={12}>
                                <Form.Label>{this.props.t('dateTo')}</Form.Label>
                                <br />
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.date_to}
                                    onChange={this.handleDateToChange}
                                    showTimeSelect
                                    // dateFormat="Pp"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                    locale="en"
                                    popperPlacement="auto-right"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["auto-right"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                                {(this.state.dateToError) ? <p className="text-left text-danger">{this.props.t(this.state.dateToError)}</p> : <p></p>}
                            </Col>
                            <Col xs={12}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitDateTo(this.state.date_to_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('event_cashless_title')}</h3>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.cashless')}</Form.Label>
                                <br />

                                <Select
                                    closeMenuOnSelect={true}
                                    options={[
                                        {value: "1", label: this.props.t('yes')},
                                        {value: "0", label: this.props.t('no')}
                                    ]}
                                    placeholder="Cashless"
                                    value={this.state.selectedCashlessValue}
                                    onChange={this.handleCashlessChange}
                                />
                                {(this.state.cashlessError) ? <p className="text-left text-danger">{this.props.t(this.state.cashlessError)}</p> : <p></p>}
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitCashless(this.state.cashless)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('event_payment_title')}</h3>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.merchant_code')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.merchant_code')} value={this.state.merchant_code} name="merchant_code" onChange={this.handleInputChange} />
                                {(this.state.merchantCodeError) ? <p className="text-left text-danger">{this.props.t(this.state.merchantCodeError)}</p> : <p></p>}
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleMerchantCode(this.state.merchant_code)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.refund_password')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.refund_password')} value={this.state.refund_password} name="refund_password" onChange={this.handleInputChange} />
                                {(this.state.refundPasswordError) ? <p className="text-left text-danger">{this.props.t(this.state.refundPasswordError)}</p> : <p></p>}
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleRefundPassword(this.state.refund_password)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('forms.config.legal_details_title')}</h3>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.legal_name')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.legal_name')} value={this.state.legal_name} name="legal_name" onChange={this.handleInputChange} />
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleConfigValue('legal_name', this.state.legal_name)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.legal_number')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.legal_number')} value={this.state.legal_number} name="legal_number" onChange={this.handleInputChange} />
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleConfigValue('legal_number', this.state.legal_number)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.legal_zip_code')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.legal_zip_code')} value={this.state.legal_zip_code} name="legal_zip_code" onChange={this.handleInputChange} />
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleConfigValue('legal_zip_code', this.state.legal_zip_code)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.legal_city')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.legal_city')} value={this.state.legal_city} name="legal_city" onChange={this.handleInputChange} />
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleConfigValue('legal_city', this.state.legal_city)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.legal_email')}</Form.Label>
                                <br />
                                <Form.Control type="email" placeholder={this.props.t('forms.config.legal_email')} value={this.state.legal_email} name="legal_email" onChange={this.handleInputChange} />
                                {(this.state.legal_emailError) ? <p className="text-left text-danger">{this.props.t(this.state.legal_emailError)}</p> : <p></p>}
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleConfigValue('legal_email', this.state.legal_email)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('forms.config.fairly_score_title')}</h3>
                    <Row>
                        <Form className="py-2 col-6">
                            <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.fairly_score_project_id')}</Form.Label>
                                <br />
                                <Form.Control type="text" placeholder={this.props.t('forms.config.fairly_score_project_id')} value={this.state.fairly_score_project_id} name="fairly_score_project_id" onChange={this.handleInputChange} />
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleConfigValue('fairly_score_project_id', this.state.fairly_score_project_id)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                        <Form className="py-2 col-6">
                        <Col xs={3}>
                                <Form.Label>{this.props.t('forms.config.fairly_score_active')}</Form.Label>
                                <br />
                                <Select
                                    closeMenuOnSelect={true}
                                    options={[
                                        {value: "1", label: this.props.t('yes')},
                                        {value: "0", label: this.props.t('no')}
                                    ]}
                                    placeholder="fairlyScoreActive"
                                    value={this.state.selectedFairlyScoreActiveValue}
                                    onChange={this.handleFairlyScoreActiveChange}
                                />
                                {(this.state.fairlyScoreActiveError) ? <p className="text-left text-danger">{this.props.t(this.state.fairlyScoreActiveError)}</p> : <p></p>}
                            </Col>
                            <Col xs={5}>
                                <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitFairlyScoreActive(this.state.fairlyScoreActive)}>{this.props.t('btn_save')}</Button>
                            </Col>
                        </Form>
                    </Row>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('forms.config.webapp')}</h3>
                    <Row>
                    </Row>
                </div>

                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />
            </section>
        );
    }
}

export default withTranslation()(EventConfig);