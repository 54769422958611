import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Button, Form} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import {SketchPicker} from 'react-color';
import {withTranslation} from 'react-i18next';

class AddCategorie extends Component {

    state = {
        nom: "",
        color: '#888888'
    }

    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    handleChange = (color) => {
        this.setState({color: color.hex});
    };


    handleSubmit = e => {
        e.preventDefault();
        if (this.state.nom.trim()) {
            const {nom, color} = this.state;
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
            };

            axios.post(`${BASE_URL}/api/events`, {nom, color}, {headers})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({show: true});
                        setTimeout(() => {
                            this.props.history.push("/dashboard/categories");
                        }, 2000);
                    }
                })
                .catch(error => {
                    throw (error);
                });
        }
    };



    handleReset = () => {
        this.setState({
            nom: "",
            color: ""
        });
    };

    render () {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('add_categorie')}</h1>
                <br />
                <div className="row justify-content-left">
                    <div className="col-md-6">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formLabel">
                                <Form.Label>{this.props.t('name')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('name')} name="nom" onChange={this.handleInputChange} required />
                            </Form.Group>
                            <Form.Group controlId="formDevice">
                                <Form.Label>{this.props.t('color')}</Form.Label>
                                <SketchPicker
                                    color={this.state.color}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <div className="mt-4 text-right">
                                <Button className="mr-2" variant="outline-secondary" onClick={this.handleReset} type="reset">{this.props.t('btn_cancel')}</Button>
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                            </div>
                        </Form>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        text="Success"
                        confirmButtonText='OK'
                        type="success"
                        onConfirm={() => this.setState({show: false})}
                    />
                </div>


            </div>
        );
    }
}

export default withTranslation()(AddCategorie);