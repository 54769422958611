import React, {Component, Suspense} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Dashboard from './components/admin/index.js'
import Header from './components/layouts/header.js';
import Login from './components/layouts/form_login.js';
import Register from './components/layouts/form_register.js';
import ForgotPassword from './components/layouts/forgot_password.js'
import ChangePassword from './components/layouts/change_pass.js'
import Home from './components/layouts/index.js';
import Webui from './webui/index';
import Benef from './webui/benef';
import History from './webui/history';

import './assets/css/App.css';
const URL_WEBUI = process.env.REACT_APP_URL_WEBUI;
//const URL_WEBUI = "http://web.local/#/webui";
class App extends Component {


  constructor (props) {
    super(props)
    this.login = this.login.bind(this)
    this.state = {isAuth: false, appMount: false}
    this.callLogout = this.callLogout.bind(this)
  }


  login () {
    return new Promise((resolve, reject) => {
      setTimeout(() => {this.setState({isAuth: true}); resolve(true);}, 300)
    });
  }

  componentDidMount () {
    if ('token' in window.localStorage) {
      this.setState({isAuth: true});
    }
    this.setState({appMount: true});
  }

  callLogout () {
    this.setState({isAuth: false});
    console.log("______logout");

    return new Promise((resolve, reject) => {
      window.localStorage.clear();
      resolve(true);
    });
  }

  render () {

    let route;

    if (this.state.isAuth === true && 1 === 2) {
      route = (
        <Route

          path='/dashboard'
          render={(props) => <Dashboard {...props} appMount={this.state.appMount} callLogout={this.callLogout} isAuth={this.state.isAuth} />}
        />
      );
    }
    else {
      route = (
        <div>


        </div>
      );
    }

    return (
      <Suspense fallback="loading">
        {(window.location.href === URL_WEBUI) ?
          <div className="content_main">
            <Router>
              <Switch>
                <Route
                  exact
                  path='/webui'
                  component={(props) => <Webui />}
                />
              </Switch>
            </Router>
          </div>
          :


          <div>
            <Header isAuth={this.state.isAuth} callLogout={this.callLogout} />
            <div className="content_main">
              <Router>
                <Switch>
                  <Route

                    path='/dashboard'
                    component={(props) => <Dashboard {...props} login={this.login} isAuth={this.state.isAuth} appMount={this.state.appMount} callLogout={this.callLogout} isAuth={this.state.isAuth} />}
                  />

                  <Route
                    exact
                    path='/'
                    component={(props) => <Home {...props} login={this.login} isAuth={this.state.isAuth} />}
                  />

                  <Route
                    exact
                    path='/login'
                    component={(props) => <Login {...props} login={this.login} isAuth={this.state.isAuth} />}
                  />

                  <Route
                    exact
                    path='/register'
                    component={(props) => <Register {...props} login={this.login} isAuth={this.state.isAuth} />}
                  />
                  <Route
                    exact
                    path='/forgot_password'
                    component={(props) => <ForgotPassword {...props} login={this.login} isAuth={this.state.isAuth} />}
                  />
                  <Route
                    exact
                    path='/pass/:key'
                    component={(props) => <ChangePassword {...props} login={this.login} isAuth={this.state.isAuth} />}
                  />

                  <Route
                    exact
                    path='/webui/beneficiaires'
                    component={(props) => <Benef />}
                  />
                  <Route
                    exact
                    path='/webui/beneficiaires/hirtory'
                    component={(props) => <History />}
                  />
                </Switch>
              </Router>
            </div>
          </div>
        }
      </Suspense>
    );
  }
}

export default App;
