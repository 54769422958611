import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form, Row } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
class EditCampaign extends Component {

    state = {
        label: "",
        description: "",
        targetAmount: 0,
        errors: {},
        active: false,
        click: {},
        campaignItems : {},
        totalTargetAmount: 0,
    }

    constructor(props) {
        super(props);
        this.handleAddItems = this.handleAddItems.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleConsign = this.handleConsign.bind(this);
        this.handleHasTaxChange = this.handleHasTaxChange.bind(this);
        this.handleClearRules = this.handleClearRules.bind(this);
    }

    makeid (length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        console.log('componentDidMount')
        // get campaign by id
        axios.get(`${BASE_URL}/api/campaigns/${this.props.match.params.id}`, {headers})
            .then(res => {
                let campaignItems = {};
                if (res.data.status === "success") {
                    const campaign = res.data.data;
                    
                    let totalTargetAmount = 0;
                    if (campaign) {
                        campaign.CampaignItems.forEach(_rep => {
                            console.log('_rep', _rep.id);
                            let key = this.makeid(10);
                            // campaignItems[key] = _rep;
                            campaignItems[_rep.id] = {label: _rep.label, targetAmount: _rep.targetAmount};
                            totalTargetAmount = parseFloat(totalTargetAmount) + parseFloat(_rep.targetAmount);
                        });
                    }
                    this.setState({
                        label: campaign.label,
                        description: campaign.description,
                        targetAmount: campaign.targetAmount,
                        campaignItems: campaignItems,
                        click: campaignItems,
                        totalTargetAmount: totalTargetAmount
                    });
                } else {
                }

            })
            .catch(err => {
            });
    }

    handleChange = (_click) => (e) => {
        e.preventDefault();
        let ResInput = Object.assign({}, this.state.click);
        ResInput[_click][e.target.name] = e.target.name === "targetAmount" ? parseFloat(e.target.value) : e.target.value;
        if (e.target.name === "targetAmount") {
            let targetAmount = 0;
            Object.keys(this.state.click).forEach(_click => {
                targetAmount = parseFloat(targetAmount) + parseFloat(this.state.click[_click].targetAmount);
            });
            this.state.totalTargetAmount = targetAmount;
        }

        this.setState({
            click: ResInput
        });
    }

    // onClick add campaign item
    handleAddItems = e => {
        e.preventDefault();
        let ResClick = Object.assign({}, this.state.click);
        let key = this.makeid(10);
        ResClick[key] = {label: '', targetAmount: 0};

        this.setState({
            click: ResClick
        });
    };
    handleConsign = e => {
        this.setState({
            consigne: e.target.checked
        });
    }

    handleInputChange (e) {
        e.preventDefault();
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    };

    setTotalTotalTargetAmount (total) {

        if (total && total != 0) {
            this.state.totalTargetAmount = total;
            return;
        }
        let totalTargetAmount = 0;
        Object.keys(this.state.click).forEach(_click => {
            totalTargetAmount = parseFloat(totalTargetAmount) + parseFloat(this.state.click[_click].targetAmount);
        });
        this.state.totalTargetAmount = totalTargetAmount;
        console.log('totalTargetAmount: ', totalTargetAmount);
        return;
    }

    handleHasTaxChange (e) {
        const value = e.target.checked;
        this.setState({
            [e.target.name]: value
        });
    };


    handleClearRules = (_click) => (e) => {
        e.preventDefault();
        delete this.state.click[_click];
        this.setTotalTotalTargetAmount();
        this.setState({
            click: this.state.click
        })
    }
    handleSubmit = e => {
        e.preventDefault();

        const {label, description, active, targetAmount} = this.state;

        this.setState({
            errors: {}
        });
        let campaignItems = [];
        let totalTargetAmount = 0;
        Object.keys(this.state.click).forEach(_click => {
            totalTargetAmount = parseFloat(totalTargetAmount) + parseFloat(this.state.click[_click].targetAmount);
            campaignItems.push(this.state.click[_click]);
        });

        // POST campaign
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };

        axios.put(`${BASE_URL}/api/campaigns/${this.props.match.params.id}`, {label, description, targetAmount, active: 1, items: campaignItems}, {headers})
        .then(res => {
            if (res.data.status === "success") {
                this.setState({
                    show: true,
                    type_alert: "success",
                    message: "Success"
                });
                setTimeout(() => {
                    this.props.history.push("/dashboard/campaigns");
                }, 2000);
            }
        }).catch(error => {
            console.log(error);
        })
    };

    handleCancel = () => {
        this.props.history.push(`/dashboard/campaigns`);
    };

    render() {
        return (
            <div className="col-md-10">
                <div className="row justify-content-left">
                    <div className="col-md-4">
                        <h1>{this.props.t('forms.campaigns.edit_campaign')}</h1>
                    </div>
                    <div className="col-md-8">
                        {this.state.totalTargetAmount && Object.keys(this.state.click).length > 0 &&
                            <h4 className={(parseFloat(this.state.totalTargetAmount.toString()) === parseFloat(this.state.targetAmount.toString())) ? 'text-success' : 'text-danger'}>{this.props.t('forms.campaigns.totalTargetAmount')} {this.state.totalTargetAmount} {this.state.targetAmount}</h4>
                        }
                    </div>
                </div>
                <div className="row justify-content-left">
                    <div className="col-md-12">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formLabel">
                                    <Form.Label>{this.props.t('forms.campaigns.label')}</Form.Label>
                                    <Form.Control type="text" value={this.state.label} placeholder="Label" name="label" onChange={this.handleInputChange} required />
                                    {(this.state.errors && "label" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.label.field}.${this.state.errors.label.validatorKey}`)}</p> : <p></p>}
                                </Form.Group>
                                <Form.Group controlId="formTargetAmount">
                                    <Form.Label>{this.props.t('forms.campaigns.targetAmount')}</Form.Label>
                                    <Form.Control type="number" value={this.state.targetAmount} placeholder="€" name="targetAmount" onChange={this.handleInputChange} required />
                                    {(this.state.errors && "targetAmount" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.label.field}.${this.state.errors.label.validatorKey}`)}</p> : <p></p>}
                                </Form.Group>
                                <Form.Group controlId="formDescription">
                                    <Form.Label>{this.props.t('forms.campaigns.description')}</Form.Label>
                                    <Form.Control as="textarea" value={this.state.description} rows="3" name="description" onChange={this.handleInputChange} />
                                </Form.Group>
                                <div className="text-right">
                                    <Button className="mr-2" variant="outline-dark" onClick={this.handleAddItems} >{this.props.t('forms.campaigns.add_items')}</Button>
                                </div>
                                <div className="mt-4 text-right">
                                    <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                                    <Button className=" btn btn-link mr-2" variant="outline-link" onClick={this.handleCancel}>
                                        {this.props.t('retour_liste')}
                                    </Button>
                                </div>
                            </div>
                                <div className="col-md-8">
                                    {Object.keys(this.state.campaignItems).map((_click, index) =>
                                        <Form.Group controlId="formItems" key={index}>
                                            <Row>
                                                <div className="col-3">
                                                    <Form.Control type="text" value={this.state.click[_click].label} placeholder={this.props.t('label')} name="label" onChange={this.handleChange(_click)} required />
                                                </div>
                                                <div className="col-2">
                                                    <Form.Control type="number" value={this.state.click[_click].targetAmount} placeholder={this.props.t('targetAmount')} name="targetAmount" onChange={this.handleChange(_click)} required />
                                                </div>
                                                <div className="col-2">
                                                    <span className="btn btn-link" onClick={this.handleClearRules(_click)} >
                                                        <FeatherIcon icon="x-circle" size="28" color="#f36b21" />
                                                    </span>
                                                </div>
                                            </Row>
                                        </Form.Group>
                                    )}
                                </div>
                        </div>

                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Insertion avec succès"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({ show: false })}
                />

            </div>
        );
    }
}

export default withTranslation()(EditCampaign);