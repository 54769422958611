import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Item extends Component {

  state = {
    items: [],
    _msg: '',
    repartitions: []
  }

  constructor(props, context) {
    super(props, context);
    this.handleDelete = this.handleDelete.bind(this);

  }

  componentDidMount() {
    const Authorization = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authorization,
    };
    if ('token' in localStorage) {


      axios.get(`${BASE_URL}/api/repartition`, { headers })
        .then(res => {
          const repartitions = res.data.repartitions;
          this.setState({ repartitions: repartitions });

          return axios.get(`${BASE_URL}/api/items`, { headers })
        })
        .then(res => {
          const items = res.data.items;

          this.setState({
            items: items
          });

        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  handleDelete = (idItem) => {
    const Authorization = localStorage.getItem('token');
    const headers = {
      'Authorization': Authorization,
      'Content-Type': 'application/json'
    };

    axios.delete(`${BASE_URL}/api/items/item/${idItem}`, { headers })
      .then(response => {
        this.setState({
          show: true,
          _msg: "Delete Success"
        });
        if ('token' in localStorage) {
          axios.get(`${BASE_URL}/api/items`, { headers })
            .then(res => {
              const items = res.data.items;

              this.setState({
                items: items
              });

            })
            .catch(error => {
              console.log(error);
            });
        }

      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const editItemFormatter = (cell, row, rowIndex) => {
      return <div className="d-flex justify-content-around align-items-center">
        <Link to={"/dashboard/pitems/" + cell} className="py-0">
          <FeatherIcon icon="edit" size="28" color="#36909b" />
        </Link>
        <Button variant="outline-link" className="ml-0"
          onClick={() => {
            if (window.confirm("Etes-vous sûre de vouloir supprimer cet Item ?")) {
              this.handleDelete(cell);
            }
          }}
        >
          <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
        </Button>
      </div>

    };
    const posFormatter = (cell, row, rowIndex) => {
      return cell.map((_pos, i) =>
        <ul key={"pos" + i} className="pl-3 mb-1">
          <li style={{ lineHeight: 1.2 }}>
            {_pos.labelPOS}
          </li>
        </ul>
      )
    };
    const posPriceFormatter = (cell, row, rowIndex) => {
      return cell.map((_pos, i) =>
        <ul key={"pos" + i} className="pl-3 mb-1">
          <li style={{ lineHeight: 1.2 }}>
            {_pos.PItem.prixItem}
          </li>
        </ul>
      )
    };
    const posRepartitionFormatter = (cell, row, rowIndex) => {
      return cell.map((_pos, i) =>
        <ul key={"rep" + i} className="pl-3 mb-1">
          <li style={{ lineHeight: 1.2 }}>
            {this.state.repartitions.map((rep, j) =>
              <span key={j}>{(rep.id === _pos.PItem.repartition_id) ? rep.nom : ''}</span>
            )}
          </li>
        </ul>
      )
    };
    const posStockFormatter = (cell, row, rowIndex) => {
      return cell.map((_pos, i) =>
        <ul key={"stock" + i} className="pl-3 mb-1">
          <li style={{ lineHeight: 1.2 }}>
            {_pos.PItem.stockItem}
          </li>
        </ul>
      )
    };

    const cols = [{
      dataField: 'labelItem',
      text: 'Label',
      sort: true
    }, {
      dataField: 'POs',
      text: this.props.t('pos'),
      formatter: posFormatter,
    }, {
      dataField: 'POs',
      text: this.props.t('prix'),
      formatter: posPriceFormatter,
    }, {
      dataField: 'POs',
      text: this.props.t('stock_left'),
      formatter: posStockFormatter,
    }, {
      dataField: 'POs',
      text: this.props.t('repartition'),
      formatter: posRepartitionFormatter,
    },
    {
      dataField: 'idItem',
      text: 'Actions',
      formatter: editItemFormatter
    },
    ];

    return (
      <div className="col-md-12">
        <h1 className="mb-3">{this.props.t('items')}</h1>
        <div className="table-responsive">
          <BootstrapTable
            keyField='idItem'
            data={this.state.items}
            columns={cols}
            pagination={paginationFactory()}
          />
        </div>
        <SweetAlert
          show={this.state.show}
          text={this.state._msg}
          type="success"
          onConfirm={() => this.setState({ show: false })}
        />
      </div>
    );
  }
}

export default withTranslation()(Item);