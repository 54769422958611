import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Button, Form, Row} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import FeatherIcon from 'feather-icons-react';
import {withTranslation} from 'react-i18next';
//import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";
class AddRepartition extends Component {

    state = {
        nom: "",
        is_default: false,
        beneficiaire_id: [],
        pourcentage: [],
        click: {},
        beneficiaires: [],
        message: "",
        type_alert: "",
        totalPercentage: "",
    };
    constructor (props) {
        super(props);

        this.handleAddRules = this.handleAddRules.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClearRules = this.handleClearRules.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };

        axios.get(`${BASE_URL}/api/beneficiaires`, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    const beneficiaires = res.data.beneficiares;
                    this.setState({
                        beneficiaires: beneficiaires
                    });
                }
            })
            .catch(err => {

            });
    }

    makeid (length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // onChange beneficiaire_id / pourcentage
    handleChange = (_click) => (e) => {
        e.preventDefault();
        let ResInput = Object.assign({}, this.state.click);

        ResInput[_click][e.target.name] = e.target.name === "pourcentage" ? parseFloat(e.target.value) : parseInt(e.target.value);
        if (e.target.name === "pourcentage") {
            let pourcentage = 0;
            Object.keys(this.state.click).forEach(_click => {
                pourcentage = pourcentage + this.state.click[_click].pourcentage;
            });
            this.state.totalPercentage = pourcentage;
        }

        this.setState({
            click: ResInput
        });
    }

    // onClick add rules
    handleAddRules = e => {
        e.preventDefault();
        let ResClick = Object.assign({}, this.state.click);
        let key = this.makeid(10);
        ResClick[key] = {beneficiaire_id: 0, pourcentage: 0};

        this.setState({
            click: ResClick
        });
        console.log(this.state.click);

    };

    handleInputChange (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    handleClearRules = (_click) => (e) => {
        e.preventDefault();
        delete this.state.click[_click];
        this.setState({
            click: this.state.click
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        let _rules = [];
        let pourcentage = 0;
        Object.keys(this.state.click).forEach(_click => {
            pourcentage = pourcentage + this.state.click[_click].pourcentage;
            _rules.push(this.state.click[_click]);
        });
        console.log("% ", pourcentage);
        if (pourcentage != 100) {
            this.setState({
                show: true,
                type_alert: "warning",
                message: this.props.t('forms.repartitions.percentage_warning'),

            });
        } else {
            let duplicate = false;
            let benefices = [];
            Object.keys(this.state.click).forEach(item => {
                if (!benefices.includes(this.state.click[item]['beneficiaire_id'])) {
                    benefices.push(this.state.click[item]['beneficiaire_id']);
                } else {
                    duplicate = true;
                }

            });

            // test if beneficiaire_id is duplicate
            if (duplicate === true) {
                this.setState({
                    show: true,
                    type_alert: "warning",
                    message: "Bénéficiaire dupliqué"

                });
            }
            else {
                // POST repartion
                const Authorization = localStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': Authorization
                };
                const {nom, is_default} = this.state;


                axios.post(`${BASE_URL}/api/repartition`, {nom: nom, is_default: is_default, rules: _rules}, {headers})
                    .then(res => {
                        if (res.data.status === "success") {
                            this.setState({
                                show: true,
                                type_alert: "success",
                                message: "Success"
                            });
                            setTimeout(() => {
                                this.props.history.push("/dashboard/repartitions");
                            }, 2000);
                        }
                    }).catch(error => {
                        console.log(error);
                    })
            }
        }
    }


    render () {

        return (
            <div className="col-md-12">
                <div className="row justify-content-left">
                    <div className="col-md-4">
                        <h1>{this.props.t('add_repartition')}</h1>
                    </div>
                    <div className="col-md-8">
                        {this.state.totalPercentage && Object.keys(this.state.click).length > 0 &&
                            <h4 className={(this.state.totalPercentage === 100) ? 'text-success' : 'text-danger'}>{this.props.t('forms.repartitions.total_percentage')} {this.state.totalPercentage} %</h4>
                        }
                    </div>
                </div>
                <div className="row justify-content-left">
                    <div className="col-md-12">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group controlId="formLabel">
                                        <Form.Label>{this.props.t('name')}</Form.Label>
                                        <Form.Control type="text" placeholder={this.props.t('name')} name="nom" onChange={this.handleInputChange} required />
                                    </Form.Group>

                                    <Form.Group controlId="formIsDefault">
                                        <Form.Check type="checkbox" checked={this.state.is_default} onChange={this.handleInputChange} name="is_default" label={this.props.t('is_default')} />
                                        <p className="text-small text-left text-muted"><i>{this.props.t('forms.repartitions.is_default_desc')}</i></p>
                                    </Form.Group>

                                    <div className="text-right">
                                        <Button className="mr-2" variant="outline-dark" onClick={this.handleAddRules} >{this.props.t('add_rules')}</Button>
                                    </div>

                                    <div className="text-right">
                                        <Button className="mt-5" variant="outline-dark" type="submit" >{this.props.t('btn_save')}</Button>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {Object.keys(this.state.click).map((_click, index) =>
                                        <Form.Group controlId="formBeneficiaire" key={index}>
                                            <Row>
                                                <div className="col-5">
                                                    <Form.Control as="select" name="beneficiaire_id" onChange={this.handleChange(_click)} required>
                                                        <option value="">{this.props.t('choose_beneficiary')}</option>
                                                        {this.state.beneficiaires.map((beneficiaire, index) =>
                                                            <option key={index} value={beneficiaire.id}>{beneficiaire.nom}</option>
                                                        )}
                                                    </Form.Control>
                                                </div>
                                                <div className="col-5">
                                                    <Form.Control type="text" placeholder={this.props.t('percentage')} name="pourcentage" onChange={this.handleChange(_click)} required />
                                                </div>
                                                <div className="col-2">
                                                    <span className="btn btn-link" onClick={this.handleClearRules(_click)} >
                                                        <FeatherIcon icon="x-circle" size="28" color="#f36b21" />
                                                    </span>
                                                </div>
                                            </Row>
                                        </Form.Group>
                                    )}
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text={this.state.message}
                    confirmButtonText='OK'
                    type={this.state.type_alert}
                    onConfirm={() => this.setState({show: false})}
                />
            </div>
        );
    }
}

export default withTranslation()(AddRepartition);