import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {BASE_URL} from '../../../config.js';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import {withTranslation} from 'react-i18next';
class Agents extends Component {

    state = {
        users: [],
        text_alert: ""
    }

    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);

    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.get(`${BASE_URL}/api/users`, {headers})
            .then(res => {
                const users = res.data.utilisateurs;
                this.setState({users: users});
            });
    }
    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = (id, state) => (e) => {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        if (state === true) {
            state = 'actif';
            this.setState({text_alert: "Utilisatuer Actif"});
        } else {
            state = 'inactive';
            this.setState({text_alert: "Utilisatuer Inactif"});
        }
        console.log(`${BASE_URL}/api/users/${id}/state/${state}`);
        axios.put(`${BASE_URL}/api/users/${id}/state/${state}`, {}, {headers})
            .then(response => {
                this.setState({show: true});
                this.props.history.push("/dashboard/of");
                axios.get(`${BASE_URL}/api/users/of`, {headers})
                    .then(res => {
                        const users = res.data.utilisateurs;
                        this.setState({users: users});
                    });
            })
            .catch(error => {
                throw (error);
            });
    };

    // handleDelete = (id) => {
    //     const Authorization = localStorage.getItem('token');
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': Authorization
    //     };
    //     axios.delete(`${BASE_URL}/users/${id}`, {headers})
    //     .then(response => {
    //         this.setState({ show: true });
    //         setTimeout(() => {     
    //             axios.get(`${BASE_URL}/api/users`, {headers})
    //             .then(res => {
    //                 const users = res.data.utilisateurs;
    //                 this.setState({ users:users });
    //             });
    //         }, 2000);
    //     })
    //     .catch(error => {
    //         throw(error);
    //     });
    // }

    render () {
        return (
            <div className="col-md-12">
                <h1>{this.props.t('super_admins')}</h1>
                <br />
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">{this.props.t('nom')}</th>
                                <th scope="col">{this.props.t('prenom')}</th>
                                <th scope="col">{this.props.t('email')}</th>
                                <th scope="col">{this.props.t('phone')}</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map((user, index) =>
                                <tr key={index}>
                                    <td>{user.nom}</td>
                                    <td>{user.prenom}</td>
                                    <td>{user.email}</td>
                                    <td>{user.telephone}</td>
                                    <td>
                                        <div className="d-flex justify-content-around">
                                            <Link to={"/dashboard/agents/edit/" + user.id}>
                                                <FeatherIcon icon="edit" size="28" color="#36909b" />
                                            </Link>
                                            {/* <Button variant="outline-link" className="pt-0"
                                        onClick={() => {
                                            if (window.confirm("Etes-vous sûre de vouloir supprimer cet Agent ?")) {
                                                this.handleDelete(user.id);
                                            }
                                        }}
                                        >
                                            <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
                                        </Button> */}

                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Suppression avec succès"
                    confirmButtonText='OK'
                    type="success"
                />
            </div>
        );
    }
}

export default withTranslation()(Agents);