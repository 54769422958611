import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Link} from 'react-router-dom';
import {Button, Form} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import {SketchPicker} from 'react-color';
import {withTranslation} from 'react-i18next';
class EditCategory extends Component {

    state = {
        events: [],
        nom: "",
        color: ""
    }

    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange = (color) => {
        this.setState({color: color.hex});
    };

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };

        // get event
        axios.get(`${BASE_URL}/api/events/${this.props.match.params.id}`, {headers})
            .then(res => {
                const events = res.data.event;
                this.setState({
                    nom: events.nom,
                    color: events.color
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const {nom, color} = this.state;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        // items
        axios.put(`${BASE_URL}/api/events/${this.props.match.params.id}`, {nom, color}, {headers})
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({show: true});
                    setTimeout(() => {
                        this.props.history.push("/dashboard/categories");
                    }, 2000);
                }
            })
            .catch(error => {
                throw (error);
            });
    };

    render () {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('edit_categorie')}</h1>
                <br />
                <div className="row justify-content-left">
                    <div className="col-md-6">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formLabel">
                                <Form.Label>{this.props.t('name')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('name')} name="nom" value={this.state.nom} onChange={this.handleInputChange} required />
                            </Form.Group>

                            <Form.Group controlId="formDescription">
                                <Form.Label>{this.props.t('name')}</Form.Label>
                                <SketchPicker
                                    color={this.state.color}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <div className="mt-4 text-right">
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>

                                <Link to="/dashboard/events" className=" btn btn-link mr-2" variant="outline-link" >
                                    {this.props.t('retour_liste')}
                                </Link>
                            </div>
                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />

            </div>
        );
    }
}

export default withTranslation()(EditCategory);