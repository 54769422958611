import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Button, Form, Row, Col} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import Select, {components} from 'react-select';
import {withTranslation} from 'react-i18next';
import campaigns from './index.js';
import FeatherIcon from 'feather-icons-react';

const IndicatorsContainer = (props) => {
    return (
        <div>
            <components.IndicatorsContainer {...props} />
        </div>
    );
}
class AddCampaign extends Component {

    state = {
        label: "",
        description: "",
        targetAmount: 0,
        errors: {},
        active: false,
        click: {},
        campaignItems : [],
        totalTargetAmount: "",
    }

    constructor (props, context) {
        super(props, context);
        this.handleAddItems = this.handleAddItems.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleConsign = this.handleConsign.bind(this);
        this.handleHasTaxChange = this.handleHasTaxChange.bind(this);

    }
    // handleChange = (val) => {
    //     this.setState({pos: val});

    // }


    handleChange = (_click) => (e) => {
        e.preventDefault();
        let ResInput = Object.assign({}, this.state.click);
        ResInput[_click][e.target.name] = e.target.name === "targetAmount" ? parseFloat(e.target.value) : e.target.value;
        if (e.target.name === "targetAmount") {
            let targetAmount = 0;
            Object.keys(this.state.click).forEach(_click => {
                targetAmount = parseFloat(targetAmount) + parseFloat(this.state.click[_click].targetAmount);
            });
            this.state.totalTargetAmount = targetAmount;
        }

        this.setState({
            click: ResInput
        });
    }
    componentDidMount () {
        // const Authorization = localStorage.getItem('token');
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': Authorization,
        // };
    }

    handleCancel = () => {
        this.props.history.push(`/dashboard/campaigns`);
    };

    makeid (length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    handleConsign = e => {
        this.setState({
            consigne: e.target.checked
        });
    }

    // onClick add campaign item
    handleAddItems = e => {
        e.preventDefault();
        let ResClick = Object.assign({}, this.state.click);
        let key = this.makeid(10);
        ResClick[key] = {label: '', targetAmount: 0};

        this.setState({
            click: ResClick
        });
    };

    handleInputChange (e) {
        e.preventDefault();
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    };

    handleHasTaxChange (e) {
        const value = e.target.checked;
        this.setState({
            [e.target.name]: value
        });
    };


    roundTo (n, digits) {
        if (digits === undefined) {
            digits = 0;
        }

        var multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        return Math.round(n) / multiplicator;
    }
    parseValue (value) {
        return this.roundTo(value, 2).toFixed(2);
    }

    handleClearRules = (_click) => (e) => {
        e.preventDefault();
        delete this.state.click[_click];
        this.setState({
            click: this.state.click
        })
    }
    handleSubmit = e => {
        e.preventDefault();

        const {label, description, active, targetAmount} = this.state;

        this.setState({
            errors: {}
        });
        let campaignItems = [];
        let totalTargetAmount = 0;
        Object.keys(this.state.click).forEach(_click => {
            totalTargetAmount = parseFloat(totalTargetAmount) + parseFloat(this.state.click[_click].targetAmount);
            campaignItems.push(this.state.click[_click]);
        });

        // POST campaign
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };

        axios.post(`${BASE_URL}/api/campaigns`, {label, description, targetAmount, active: 1, items: campaignItems}, {headers})
            .then(res => {
                if (res.data.status === "success") {
                    this.setState({
                        show: true,
                        type_alert: "success",
                        message: "Success"
                    });
                    setTimeout(() => {
                        this.props.history.push("/dashboard/campaigns");
                    }, 2000);
                }
            }).catch(error => {
                console.log(error);
            })
    }
    render () {
        return (
            <div className="col-md-12">

                <div className="row justify-content-left">
                    <div className="col-md-4">
                        <h1>{this.props.t('forms.campaigns.add_campaign')}</h1>
                    </div>
                    <div className="col-md-8">
                        {this.state.totalTargetAmount && Object.keys(this.state.click).length > 0 &&
                            <h4 className={(parseFloat(this.state.totalTargetAmount.toString()) === parseFloat(this.state.targetAmount.toString())) ? 'text-success' : 'text-danger'}>{this.props.t('forms.campaigns.totalTargetAmount')} {this.state.totalTargetAmount} {this.state.targetAmount}</h4>
                        }
                    </div>
                </div>
                <div className="row justify-content-left">
                    <div className="col-md-12">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group controlId="formLabel">
                                        <Form.Label>{this.props.t('forms.campaigns.label')}</Form.Label>
                                        <Form.Control type="text" placeholder="Label" name="label" onChange={this.handleInputChange} required />
                                        {(this.state.errors && "label" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.label.field}.${this.state.errors.label.validatorKey}`)}</p> : <p></p>}
                                    </Form.Group>
                                    <Form.Group controlId="formTargetAmount">
                                        <Form.Label>{this.props.t('forms.campaigns.targetAmount')}</Form.Label>
                                        <Form.Control type="number" placeholder="€" name="targetAmount" onChange={this.handleInputChange} required />
                                        {(this.state.errors && "targetAmount" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.label.field}.${this.state.errors.label.validatorKey}`)}</p> : <p></p>}
                                    </Form.Group>
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>{this.props.t('forms.campaigns.description')}</Form.Label>
                                        <Form.Control as="textarea" rows="3" name="description" onChange={this.handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="active">
                                        {/* <Form.Check type="checkbox"
                                            checked={this.state.active}
                                            onChange={this.handleHasTaxChange} name="active" label={this.props.t('forms.campaigns.active')} /> */}
                                    </Form.Group>
                                    <div className="text-right">
                                        <Button className="mr-2" variant="outline-dark" onClick={this.handleAddItems} >{this.props.t('forms.campaigns.add_items')}</Button>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {Object.keys(this.state.click).map((_click, index) =>
                                        <Form.Group controlId="formItems" key={index}>
                                            <Row>
                                                <div className="col-3">
                                                    <Form.Control type="text" placeholder={this.props.t('label')} name="label" onChange={this.handleChange(_click)} required />
                                                </div>
                                                <div className="col-2">
                                                    <Form.Control type="number" placeholder={this.props.t('targetAmount')} name="targetAmount" onChange={this.handleChange(_click)} required />
                                                </div>
                                                <div className="col-2">
                                                    <span className="btn btn-link" onClick={this.handleClearRules(_click)} >
                                                        <FeatherIcon icon="x-circle" size="28" color="#f36b21" />
                                                    </span>
                                                </div>
                                            </Row>
                                        </Form.Group>
                                    )}
                                </div>
                            </div>
                            <div className="mt-4 text-left">
                                <Button className="mr-2" variant="outline-secondary" onClick={this.handleCancel} type="reset">{this.props.t('btn_cancel')}</Button>
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Insertion avec succès"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />
            </div>
        );
    }
}

export default withTranslation()(AddCampaign);