import React, {Component} from 'react';
import axios from 'axios';
import {BASE_URL} from '../../../config.js';
import {Button, Form, Row, Col} from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import {withTranslation} from 'react-i18next';
class AddOrganisateur extends Component {

    state = {
        nom: "",
        prenom: "",
        email: "",
        organisation: "",
        password: "",
        telephone: "",
        isOrganisteur: false
    }

    constructor (props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    handleTypeChange = e => {
        if (e.target.value === "OF_USER") {
            this.setState({isOrganisteur: true});
        }
        else {
            this.setState({isOrganisteur: false});
        }

    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        let label = "BMA_USER";
        const {nom, prenom, email, password, telephone, organisation} = this.state;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        if (this.state.isOrganisteur === true) {
            axios.post(`${BASE_URL}/api/users`, {nom, prenom, email, password, telephone, organisation}, {headers})
                .then(response => {
                    this.setState({show: true});
                    this.props.history.push("/dashboard/of");
                })
                .catch(error => {
                    throw (error);
                });
        }
        else {
            axios.post(`${BASE_URL}/api/users`, {nom, prenom, label, email, password, telephone}, {headers})
                .then(response => {
                    this.setState({show: true});
                    this.props.history.push("/dashboard/agents");
                })
                .catch(error => {
                    throw (error);
                });
        }

    };

    handleReset = () => {
        this.setState({
            nom: "",
            prenom: "",
            email: "",
            password: "",
            telephone: "",
            organisation: ""
        });
    };

    render () {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('add_user')} </h1>
                <br />
                <div className="row justify-content-left">
                    <div className="col-md-8">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formType">
                                <Form.Label>{this.props.t('type')}</Form.Label>
                                <Form.Control as="select" onChange={this.handleTypeChange} required>
                                    <option value="">{this.props.t('choose_type')}</option>
                                    <option value="BMA_USER">{this.props.t('super_admins')}</option>
                                    <option value="OF_USER">{this.props.t('clients')}</option>
                                </Form.Control>
                            </Form.Group>
                            {this.state.isOrganisteur ?
                                (
                                    <Form.Group controlId="formOrganisation">
                                        <Form.Label>{this.props.t('organisation')}</Form.Label>
                                        <Form.Control type="text" placeholder={this.props.t('organisation')} name="organisation" onChange={this.handleInputChange} required />
                                    </Form.Group>
                                )
                                :
                                (
                                    <Form.Group controlId="formOrganisation">
                                    </Form.Group>
                                )

                            }
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>{this.props.t('nom')}</Form.Label>
                                        <Form.Control type="text" placeholder={this.props.t('nom')} name="nom" onChange={this.handleInputChange} required />
                                    </Col>
                                    <Col>
                                        <Form.Label>{this.props.t('prenom')}</Form.Label>
                                        <Form.Control type="text" placeholder={this.props.t('prenom')} name="prenom" onChange={this.handleInputChange} required />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>{this.props.t('email')}</Form.Label>
                                <Form.Control type="email" placeholder={this.props.t('email')} name="email" onChange={this.handleInputChange} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.t('password')}</Form.Label>
                                <Form.Control type="password" placeholder={this.props.t('password')} name="password" onChange={this.handleInputChange} required />
                            </Form.Group>
                            <Form.Group controlId="formPhone">
                                <Form.Label>{this.props.t('phone')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('phone')} name="telephone" onChange={this.handleInputChange} />

                            </Form.Group>
                            <div className="mt-4 text-right">

                                <Button className="mr-2" variant="outline-secondary" onClick={this.handleReset} type="reset">{this.props.t('btn_cancel')}</Button>
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>


                            </div>

                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Insertion Success"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({show: false})}
                />

            </div>
        );
    }
}

export default withTranslation()(AddOrganisateur);