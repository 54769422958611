import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Pos extends Component {
    
    state = {
        pos: []
      }
      
      constructor (props,context){
        super(props,context);
        this.handleDelete = this.handleDelete.bind(this);

    }
    
      componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
          };
        axios.get(`${BASE_URL}/api/pos?type=type&visible=true`, {headers})
          .then(res => {
            const pos = res.data.pos;
            this.setState({ pos:pos });
          })
          .catch(error => {
              console.log(error);
          });
      }

    handleDelete = (id) => {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Authorization': Authorization,
            'Content-Type': 'application/json'
        };

        axios.delete(`${BASE_URL}/api/pos/${id}`, {headers})
        .then(response => {
            // this.props.history.push("/dashboard/pos");
            this.setState({ show: true });
            axios.get(`${BASE_URL}/api/pos?type=type&visible=true`, {headers})
            .then(res => {
                const pos = res.data.pos;
                this.setState({ pos:pos, show: true });
            })
            .catch(error => {
                console.log(error);
            });
        })
        .catch(error => {
            throw(error);
        });
    }

    render() {

        const actionFormatter = (cell, row, rowIndex) => {
            return <div className="d-flex justify-content-around">
                    <Link to={"/dashboard/pos/"+cell}>
                        <FeatherIcon icon="eye" size="28" color="#36909b"/>
                    </Link>
                    <Link to="/dashboard/pos"
                    onClick={() => {
                        if (window.confirm("Etes-vous sûre de vouloir supprimer ce POS ?")) {
                            this.handleDelete(cell);
                        }
                    }}
                    >
                        <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
                    </Link>
                    <SweetAlert
                        show={this.state.show}
                        title="Suppression"
                        text="Success"
                        onConfirm={() => this.setState({ show: false })}
                    />
                    </div>
          };
        const cols = [{
                dataField: 'labelPOS',
                text: 'Label',
                sort: true
            }, {
                dataField: 'descriptionPOS',
                text: 'Description'
            }, {
                dataField: 'deviceIDPOS',
                text: 'Device',
                sort: true
            }, {
                dataField: 'Repartition.nom',
                text: 'Repartition',
                formatter: (cell, row, rowIndex) => row.Repartition ? row.Repartition.nom : "",
                sort: true
            }, 
            {
                dataField: 'idPOS',
                text: 'Action',
                formatter: actionFormatter
            },
        ];
        return (
            <div className="col-md-10">
                <h1>Liste POS</h1>
                <br />
                <BootstrapTable 
                    keyField='idPOS' 
                    data={ this.state.pos } 
                    columns={ cols } 
                    pagination={ paginationFactory() }
                />
                </div>
        );
    }
}

export default withTranslation()(Pos);