
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import LeftMenu from './layouts/left_menu.js';
import '../../assets/css/dashboard.css';
import FeatherIcon from 'feather-icons-react';
import DashboardScreen from './dashboard/index.js';
import MonProfil from './profil/index.js';
import OfScreen from './of';
import AddOfScreen from './of/add.js';
import EditOfScreen from './of/edit';
import PosScreen from './pos/index.js';
import AddPosScreen from './pos/add.js';
import EditPosScreen from './pos/edit.js';
import ItemScreen from './items/index';
import AddItemScreen from './items/add.js';
import EditPItemScreen from './items/pedit.js';
import EditItemScreen from './items/edit.js';
import CampaignScreen from './campaigns/index';
import AddCampaignScreen from './campaigns/add.js';
import EditCampaignScreen from './campaigns/edit.js';
// import EditCampaignItemsScreen from './campaigns/campaignItemsEdit.js';
import SettingsScreen from './config';
import SettingsAboutScreen from './config/about';
import SettingsDatesScreen from './config/dates';
import SettingsEventConfigScreen from './config/eventConfig';
import SubscribersScreen from './config/subscribers';
import AgentsScreen from './agents';
import EditAgentsScreen from './agents/edit';
import EventsScreen from './events';
import AddEventsScreen from './events/add';
import EditEventsScreen from './events/edit';
import CategorieScreen from './categorie';
import AddCategorieScreen from './categorie/add';
import EditCategorieScreen from './categorie/_edit';
import BeneficiaireScreen from './beneficiaires';
import AddBeneficiaireScreen from './beneficiaires/add';
import EditBeneficiaireScreen from './beneficiaires/edit';
import RepartitionScreen from './repartitions';
import AddRepartitionScreen from './repartitions/add';
import EditRepartitionScreen from './repartitions/edit';

class Dashboard extends Component {
  constructor (props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.handleToggle = this.handleToggle.bind(this);

    this.state = {
      open: false,
      isShow: true
    };
  }

  handleToggle = e => {
    this.setState({
      isShow: !this.state.isShow
    });
  }
  logout () {
    this.props.callLogout()
      .then(() => {

      });

  }

  componentDidMount () {
    console.log(this.props);
    if (this.props.isAuth === false && this.props.appMount === true) {
      this.props.history.push("/login");
    }
    if (this.props.isAuth === false && this.props.appMount === true) {
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps (nextProps) {
    console.log(this.props);
    if (this.props.isAuth === false && nextProps.appMount === true) {
      this.props.history.push("/login");
    }
    if (nextProps.isAuth === false && this.props.appMount === true) {
      this.props.history.push("/login");
    }
  }

  render () {
    let routeAdmin;
    routeAdmin = (

      <Switch>
        <Route
          exact
          path='/dashboard'
          component={DashboardScreen}
        />
        <Route

          path='/dashboard/profil'
          component={MonProfil}
        />
        <Route
          exact
          path='/dashboard/of'
          component={OfScreen}
        />
        <Route

          path='/dashboard/of/ajout'
          component={AddOfScreen}
        />
        <Route

          path='/dashboard/of/:id'
          component={EditOfScreen}
        />
        <Route
          exact
          path='/dashboard/pos'
          component={PosScreen}
        />
        <Route

          path='/dashboard/pos/ajout'
          component={AddPosScreen}
        />
        <Route

          path='/dashboard/pos/:idPOS'
          component={EditPosScreen}
        />
        <Route
          exact
          path='/dashboard/items'
          component={ItemScreen}
        />
        <Route

          path='/dashboard/items/add'
          component={AddItemScreen}
        />
        <Route
          path='/dashboard/pitems/:idItem'
          component={EditPItemScreen}
        />
        <Route
          path='/dashboard/items/:idItem'
          component={EditItemScreen}
        />
        <Route
          exact
          path='/dashboard/campaigns'
          component={CampaignScreen}
        />
        <Route

          path='/dashboard/campaigns/add'
          component={AddCampaignScreen}
        />
        <Route

          path='/dashboard/campaigns/:id/edit'
          component={EditCampaignScreen}
        />
        <Route
          exact
          path='/dashboard/settings'
          component={SettingsScreen}
        />
        <Route

          path='/dashboard/settings/about'
          component={SettingsAboutScreen}
        />
        <Route
          exact
          path='/dashboard/settings/dates'
          component={SettingsDatesScreen}
        />
        <Route
          exact
          path='/dashboard/settings/config'
          component={SettingsEventConfigScreen}
        />
        <Route

          path='/dashboard/settings/subscribers'
          component={SubscribersScreen}
        />
        <Route
          exact
          path='/dashboard/agents'
          component={AgentsScreen}
        />
        <Route
          exact
          path='/dashboard/agents/edit/:id'
          component={EditAgentsScreen}
        />
        <Route
          exact
          path='/dashboard/events'
          component={EventsScreen}
        />
        <Route

          path='/dashboard/events/add'
          component={AddEventsScreen}
        />
        <Route

          path='/dashboard/events/:id'
          component={EditEventsScreen}
        />
        <Route
          exact
          path='/dashboard/categories'
          component={CategorieScreen}
        />
        <Route

          path='/dashboard/categories/add'
          component={AddCategorieScreen}
        />
        <Route

          path='/dashboard/categories/:id'
          component={EditCategorieScreen}
        />
        <Route
          exact
          path='/dashboard/beneficiaires'
          component={BeneficiaireScreen}
        />
        <Route

          path='/dashboard/beneficiaires/add'
          component={AddBeneficiaireScreen}
        />
        <Route

          path='/dashboard/beneficiaires/:id'
          component={EditBeneficiaireScreen}
        />
        <Route
          exact
          path='/dashboard/repartitions'
          component={RepartitionScreen}
        />
        <Route

          path='/dashboard/repartitions/add'
          component={AddRepartitionScreen}
        />
        <Route

          path='/dashboard/repartitions/:id'
          component={EditRepartitionScreen}
        />
      </Switch>

    );
    return (
      <div className="wrapper pt-5">
        <div>
          {this.state.isShow === true ? <LeftMenu /> : <span></span>}
        </div>
        <div id="content">
          <div className="pt-3 pb-0 mb-3">
            <button type="button" onClick={this.handleToggle} className="btn btn-link">
              <FeatherIcon icon="menu" size="28" color="#000" />
            </button>
            {routeAdmin}
          </div>

        </div>
      </div>

    );
  }
}

export default Dashboard
